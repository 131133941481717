
(function(){

	var lastTime = 0;
	var vendors = ['ms', 'moz', 'webkit', 'o'];
	for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
		window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
		window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
	}

	if(!window.requestAnimationFrame){
		window.requestAnimationFrame = function(callback, element) {
			var currTime = new Date().getTime();
			var timeToCall = Math.max(0, 16 - (currTime - lastTime));
			var id = window.setTimeout(function() { callback(currTime + timeToCall); }, 
			timeToCall);
			lastTime = currTime + timeToCall;
			return id;
		};
	}

	if(!window.cancelAnimationFrame){
		window.cancelAnimationFrame = function(id) {
			clearTimeout(id);
		};
	}
}());

/* VAR TO LOCK CHANGE PAGE */
var lockChangePage = false;

/* VAR TO LOOK EXIT PAGE, CLOSE BROWSER OR CLOSE TAB */
var lockClosePage = false;

var touchEvents = false;

/* EVENTOS DE ESCUTA APENAS UMA VEZ */
var fakescroll;
var fakeresize;

if(Event in window){
	fakescroll = new Event('fakescroll');
	fakeresize = new Event('fakeresize');
}else if(document.createEvent){

	fakescroll = document.createEvent('Event');
	fakescroll.initEvent('fakescroll', true, false);

	fakeresize = document.createEvent('Event');
	fakeresize.initEvent('fakeresize', true, false);
}

window.Boss = {
	/* TOUCH EVENT */
	evtTouch: function(){
		if (window.navigator.msPointerEnabled) {
			return 'MSPointerDown';
		}else if('ontouchstart' in window && touchEvents === true){
			return 'touchstart';
		}else{
			return 'mousedown';
		}
	},
	/* UP AND END TOUCH EVENT */
	evtTouchUp: function(){
		if (window.navigator.msPointerEnabled) {
			return 'MSPointerUp';
		}else if('ontouchend' in window && touchEvents === true){
			return 'touchend';
		}else{
			return 'mouseup';
		}
	},
	/* TOUCH MOVE EVENT */
	evtTouchMove: function(){
		if('ontouchmove' in window && touchEvents === true){
			return 'touchmove';
		}else{
			return 'mousemove';
		}
	},
	autoClip: function(id){
		if(Boss.getById(id)){
			Boss.evts.add('click', Boss.getById(id), function(evts){
				var el = Boss.targt(evts);
				if(el.classList.contains('boss-auto-clipboard')){
					Boss.clip(el);
					el.classList.add('boss-auto-clipboard-sucess');
					Boss.delay(function(){
						el.classList.remove('boss-auto-clipboard-sucess');
					}, 2000);
				}
			});
		}
	},
	clip: function(el){
		var tx = document.createElement('textarea');
		tx.textContent = el.textContent.replace(/\s+$/, '').replace(/^\s+/, '');
		tx.focus();
		tx.classList.add('hidden');
		tx.style.width = '1px';
		tx.style.height = '1px';
		el.appendChild(tx);
		tx.select();
		document.execCommand('copy');
		Boss.delay(function(){
			Boss.remover(tx);
		}, 10);
	},
	slct: {
		data: {},
		filter: function(elem, select, arr){

			if(Boss.getById(elem) && Boss.getById(select) && Boss.getById(select).nodeName === 'SELECT'){

				var el = Boss.getById(elem);
				var sel = Boss.getById(select);
				var ul = Boss.getById('ul_'+elem+'_ul');
				ul.innerHTML = '';
				var opts = sel.getElementsByTagName('option');
				var lopts = opts.length;

				for(var x = 0; x < lopts; x++){

					if(arr === false){
						opts[x].removeAttribute('disabled');
					}else{

						if(opts[x].value !== ''){
							if(typeof(arr[opts[x].value]) !== 'undefined'){
								opts[x].removeAttribute('disabled');
							}else{
								opts[x].setAttribute('disabled', 'disabled');
							}
						}

					}
				}

				this.render(elem, ul, opts);
			}

		},
		setValue: function(elem, select, val){

			if(Boss.getById(elem)){

				var sel = Boss.getById(select);
				var ul = Boss.getById('ul_'+elem+'_ul');
				var lb = Boss.getById('label-'+elem);
				var opts = sel.getElementsByTagName('option');
				var lopts = opts.length;

				ul.innerHTML = '';
				lb.innerHTML = '';

				for(var x = 0; x < lopts; x++){
					if(opts[x].value == val){
						opts[x].setAttribute('selected', 'selected');
					}else{
						opts[x].removeAttribute('selected');
					}
				}

				this.render(elem, ul, opts);

			}
		},
		render: function(elem, ul, opts){

			var lopts = opts.length;
			var lb = Boss.getById('label-'+elem);

			for(var x = 0; x < lopts; x++){

				if(!opts[x].getAttribute('disabled')){
					var li = document.createElement('li');
					li.setAttribute('data-value', opts[x].getAttribute('value'));
					li.setAttribute('data-label', opts[x].textContent);

					/* IF EXISTS IMAGE */
					if(opts[x].getAttribute('data-img') !== null && opts[x].getAttribute('data-img') !== ''){

						var img = document.createElement('img');
						img.setAttribute('src', opts[x].getAttribute('data-img'));
						li.appendChild(img);

						li.setAttribute('data-img', opts[x].getAttribute('data-img'));

					}else if(opts[x].getAttribute('data-icon') !== null && opts[x].getAttribute('data-icon') !== ''){

						var i = document.createElement('i');
						i.setAttribute('class', opts[x].getAttribute('data-icon'));

						li.appendChild(i);
						li.setAttribute('data-icon', opts[x].getAttribute('data-icon'));
					}

					var tcon = document.createTextNode(' '+opts[x].textContent);

					li.appendChild(tcon);

					ul.appendChild(li);

					if(opts[x].getAttribute('selected') !== null){

						/* IF EXISTS IMAGE */
						if(opts[x].getAttribute('data-img') !== null && opts[x].getAttribute('data-img') !== ''){
							lb.innerHTML = '<img src="'+opts[x].getAttribute('data-img')+'" alt="'+opts[x].textContent+'" />'+opts[x].textContent;

						}else if(opts[x].getAttribute('data-icon') !== null && opts[x].getAttribute('data-icon') !== ''){

							lb.innerHTML = '<i class="'+opts[x].getAttribute('data-icon')+'"></i>&nbsp;'+opts[x].textContent;
						}else{

							lb.textContent = opts[x].textContent;
						}
					}
				}
			}
		},
		init: function(elem, select){

			if(Boss.getById(elem) && Boss.getById(select) && Boss.getById(select).nodeName === 'SELECT'){

				var el = Boss.getById(elem);
				var sel = Boss.getById(select);

				el.setAttribute('tabindex', '0');
				el.setAttribute('data-last-evt', 'blur');
				el.setAttribute('data-open', 'close');
				sel.setAttribute('tabindex', '-1');

				var disabled = false;
				if(sel.getAttribute('disabled') !== null && sel.getAttribute('disabled') == 'disabled'){
					disabled = true;
				}

				if(disabled === true){
					el.classList.add('boss-slct-disabled');
				}

				if(!Boss.getById('label-'+elem)){

					var lb = document.createElement('div');
					lb.classList.add('boss-slct-label');
					lb.setAttribute('id', 'label-'+elem);

				}else{
					var lb = Boss.getById('label-'+elem);
				}

				var dv = document.createElement('div');
				dv.style.height = '0px';
				dv.style.opacity = '0';
				dv.classList.add('boss-slct-area');
				dv.setAttribute('id', 'boss-slct-area'+elem);
				dv.style.top = (el.clientHeight - 2)+'px';

				var ul = document.createElement('ul');
				ul.setAttribute('id', 'ul_'+elem+'_ul');

				var opts = sel.getElementsByTagName('option');

				if(opts[0]){

					/* IF EXISTS IMAGE */
					if(opts[0].getAttribute('data-img') !== null && opts[0].getAttribute('data-img') !== ''){

						var img = document.createElement('img');
						img.setAttribute('src', opts[0].getAttribute('data-img'));
						lb.appendChild(img);

					}else if(opts[0].getAttribute('data-icon') !== null && opts[0].getAttribute('data-icon') !== ''){

						var i = document.createElement('i');
						i.setAttribute('class', opts[0].getAttribute('data-icon'));
						lb.appendChild(i);

					}

					var tcon = document.createTextNode(' '+opts[0].textContent);

					lb.appendChild(tcon);
				}

				/* CLICK/TOUCH IN LABEL */
				Boss.evts.add(Boss.evtTouchUp(), lb, function(evt){

					var dv = Boss.getById('boss-slct-area'+elem);
					var el = Boss.getById(elem);
					if(el.classList.contains('boss-slct-disabled') === false){
	
						var sizes = Boss.screensizes();
						var viewHeight = sizes.viewHeight;

						var scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

						/* COLISÃO BOTTOM */
						if((viewHeight+scrollY) < (Boss.positionAtTop(el) + dv.clientHeight)){
						
							dv.style.top = '-'+dv.clientHeight+'px';

						/* SEM COLISÃO BOTTOM */
						}else{

							dv.style.top = (el.clientHeight - 2)+'px';

						}

						var hpx = dv.style.height.replace('px', '');

						if(hpx == '0'){
							dv.style.height = 'auto';
							dv.style.height = dv.clientHeight+'px';
							dv.style.opacity = '1';
							el.setAttribute('data-last-evt', 'focus');
							el.setAttribute('data-open', 'open');
						}else{
							dv.style.height = '0px';
							dv.style.opacity = '0';
							el.setAttribute('data-last-evt', 'blur');
							el.setAttribute('data-open', 'close');
						}

						Boss.delay(function(){
							Boss.trigger('fakescroll', window);
						}, 160);
					}
				});

				/* SCROLL IN WINDOW LABEL */
				Boss.evts.add('fakescroll', window, function(evt){

					if(Boss.getById(elem) && Boss.getById('boss-slct-area'+elem)){

						var dv = Boss.getById('boss-slct-area'+elem);
						var el = Boss.getById(elem);
						var sizes = Boss.screensizes();
						var viewHeight = sizes.viewHeight;

						var scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

						/* COLISÃO BOTTOM */
						if((viewHeight+scrollY) < (Boss.positionAtTop(el) + dv.clientHeight)){
						
							dv.style.top = '-'+dv.clientHeight+'px';

						/* SEM COLISÃO BOTTOM */
						}else{

							dv.style.top = (el.clientHeight - 2)+'px';

						}
					}
				});

				/* CLICK IN LI OPTION AND TRIGGER CHANGE IN SELECT */
				Boss.evts.add('click', el, function(evt){

					var targt = Boss.targt(evt);
					var dv = Boss.getById('boss-slct-area'+elem);

					if(el.classList.contains('boss-slct-disabled') === false){
						el.classList.add('focus');

						if(targt.nodeName === 'LI'){
							if(targt.getAttribute('data-label') !== null){

								if(targt.getAttribute('data-img') !== null && targt.getAttribute('data-img') !== ''){

									lb.innerHTML = '<img src="'+targt.getAttribute('data-img')+'" alt="'+targt.getAttribute('data-label')+'" /> '+targt.getAttribute('data-label');
								}else if(targt.getAttribute('data-icon') !== null && targt.getAttribute('data-icon') !== ''){

									lb.innerHTML = '<i class="'+targt.getAttribute('data-icon')+'" alt="'+targt.getAttribute('data-label')+'"></i>&nbsp;'+targt.getAttribute('data-label');
								}else{
									lb.textContent = targt.getAttribute('data-label');
								}
							}
							if(targt.getAttribute('data-value') !== null){
								sel.value = targt.getAttribute('data-value');

								Boss.delay(function(){
									Boss.trigger('change', Boss.getById(select));
									dv.style.height = '0px';
									dv.style.opacity = '0';
								}, 160);
							}
						}
					}
				});

				/* BLUR */
				Boss.evts.add('blur', el, function(evts){

					Boss.delay(function(){

						var elemt = Boss.getById(elem);

						/* IF THE BLUR EVENT WAS HAPPENED OUT THE COMPONENT */
						if(elemt.getAttribute('data-last-evt') == 'focus' && elemt.getAttribute('data-open') == 'open' && Boss.focusOut(elemt, evts.relatedTarget) === false){
						
							elemt.setAttribute('data-last-evt', 'blur');
							elemt.setAttribute('data-open', 'close');

							var dv = Boss.getById('boss-slct-area'+elem);
							var el = Boss.getById(elem);
							el.classList.remove('focus');

							var hpx = dv.style.height.replace('px', '');

							dv.style.height = '0px';
							dv.style.opacity = '0';

						}
					}, 150);

				});

				/* FOCUS */
				Boss.evts.add('focus', el, function(evts){

					Boss.delay(function(){

						var elemt = Boss.getById(elem);

						if(elemt.getAttribute('data-last-evt') == 'blur' && elemt.getAttribute('data-open') == 'close' && elemt.classList.contains('boss-slct-disabled') === false){

							elemt.setAttribute('data-last-evt', 'focus');
							elemt.setAttribute('data-open', 'open');

							var spn = elemt.getElementsByTagName('span')[0];

							var dv = Boss.getById('boss-slct-area'+elem);
							var el = Boss.getById(elem);

							if(el.classList.contains('boss-slct-disabled') === false){
								el.classList.add('focus');

								dv.style.height = 'auto';
								dv.style.height = dv.clientHeight+'px';
								dv.style.opacity = '1';

								Boss.delay(function(){
									Boss.trigger('fakescroll', window);
								}, 350);
							}
						}
					}, 150);
				});

				dv.appendChild(ul);

				el.appendChild(lb);

				el.appendChild(dv);

				/* RENDER */
				this.render(elem, ul, opts);
			}
		}
	},
	dialog: {
		open: function(obj){

			var c = document.createElement('button');
			c.classList.add('boss-dialog-close');
			c.setAttribute('id', 'boss-dialog-close');
			c.innerHTML = '<i class="icl ic-times" aria-hidden="true"></i>';

			obj.html = obj.html.split('{{dialogs}}').join('');

			if(!Boss.getById('boss-dialog')){
				var dialog = document.createElement('div');
				dialog.setAttribute('id', 'boss-dialog');

				var area = document.createElement('div');
				area.classList.add('boss-dialog-area');
				area.innerHTML = obj.html;

				if(obj.close){
					area.appendChild(c);
				}

				dialog.appendChild(area);
				document.body.appendChild(dialog);

			}else{

				var dialog = Boss.getById('boss-dialog');
				dialog.classList.remove('hidden');
				dialog.innerHTML = '';

				var area = document.createElement('div');
				area.classList.add('boss-dialog-area');
				area.innerHTML = obj.html;

				if(obj.close){
					area.appendChild(c);
				}

				dialog.appendChild(area);
			}

			document.body.style.overflow = 'hidden';
			document.body.style.marginLeft = '-9px';
			if(Boss.getById('content')){
				Boss.getById('content').style.webkitFilter = "blur(2px)";
			}
			
			if(obj.invisible){
				area.classList.add('boss-dialog-invisible');
			}

			Boss.delay(function(){
				var scripts = dialog.getElementsByTagName('script');
				for(x in scripts){
					eval(scripts[x].innerHTML);
				}
			}, 10);

			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('boss-dialog-close'), function(evts){

				Boss.dialog.close();

				if(obj.callBack && typeof(obj.callBack) === 'function'){
					obj.callBack();
				}
			});
		},
		close: function(){

			if(Boss.getById('boss-dialog')){

				document.body.style.overflow = null;
				document.body.style.margin = null;
				if(Boss.getById('content')){
					Boss.getById('content').style.webkitFilter = 'none';
				}
				var dialog = Boss.getById('boss-dialog');
				Boss.remover(dialog);

			}
		}
	},
	/* inputsearch: {
		mask: '<button type="button" data-codigo="{{codigo}}" class="button"><span class="left"><img class="img" src="{{img}}" alt="" /></span><span class="right"><span class="label">{{label}}</span></span></button>',
		setValue: function(elem, render, data, codigo){

			if(codigo == ''){
				codigo = 0;
			}

			if(Boss.getById(elem), Boss.getById(render)){

				var el = Boss.getById(elem);
				var render = Boss.getById(render);
				var ipt = Boss.getById(elem+'_hidden');
				var img = el.querySelector('[data-src]');
				var lbl = el.querySelector('[data-label]');

				for(var x in data){
					if(codigo == data[x].codigo){

						img.src = data[x].img;
						lbl.innerHTML = data[x].label;
						ipt.value = data[x].codigo;
						break;
					}
				}
				render.style.display = 'none';
			}
		},
		render: function(elem, render, data){
			Boss.render(data, {
				renderTo: render,
				mask: this.mask,
				empty: '',
				search: Boss.getById(elem+'_search').value,
				filters: {}
			});
		},
		init: function(elem, render, data){

			if(Boss.getById(elem) && Boss.getById(render)){

				this.render(elem, render, data);

				var ipt = Boss.getById(elem+'_hidden');
				if(ipt.value !== ''){
					Boss.inputsearch.setValue(elem, render, data, ipt.value);
				}

				Boss.evts.add('click', Boss.getById(elem), function(evts){
					var el = Boss.targt(evts);

					if(el.nodeName !== 'BUTTON' && (el.nodeName === 'IMG' || el.nodeName === 'SPAN' || el.parentElement.nodeName === 'BUTTON')){
						el = el.parentElement;
					}
					if(el.nodeName !== 'BUTTON' && (el.nodeName === 'IMG' || el.nodeName === 'SPAN' || el.parentElement.nodeName === 'BUTTON')){
						el = el.parentElement;
					}
					if(el !== null && el.getAttribute('data-codigo') && el.getAttribute('data-codigo') != ''){
						Boss.inputsearch.setValue(elem, render, data, el.getAttribute('data-codigo'));
					}
				});

				Boss.evts.add('keyup', Boss.getById(elem+'_search'), function(evts){
					Boss.inputsearch.render(elem, render, data);
				});

				Boss.evts.add('focus', Boss.getById(elem+'_search'), function(evts){
					Boss.getById(render).style.display = 'block';
				});
			}
		}
	}, */
	selectMultiple: {
		data: {},
		setValue: function(elemtString, selctString, val){

			var elemt = Boss.getById(elemtString);
			var selct = Boss.getById(selctString);
			var ul = Boss.getById('list-'+elemtString).getElementsByTagName('ul')[0];
			var lines = selct.getElementsByTagName('option');
			var tlines = lines.length;

			var checkeds = 0;

			for(x = 0; x < tlines; x++){
				if(lines[x].value === val){
					lines[x].selected = true;
					Boss.getById('id-'+elemtString+'-'+x).checked = true;
				}

				if(lines[x].value !== '' && lines[x].selected === true){
					checkeds = checkeds + 1;
				}
			}

			if(checkeds < 1){

				for(x = 0; x < tlines; x++){

					if(lines[x].value === ''){
						lines[x].selected = true;
						Boss.getById('id-'+elemtString+'-').checked = true;
					}
				}

			}else{

				for(x = 0; x < tlines; x++){
					if(lines[x].value === ''){
						lines[x].selected = false;
						Boss.getById('id-'+elemtString+'-').checked = false;
					}
				}
			}
		},
		unsetValue: function(elemtString, selctString, val){

			var elemt = Boss.getById(elemtString);
			var selct = Boss.getById(selctString);

			var ul = Boss.getById('list-'+elemtString).getElementsByTagName('ul')[0];
			var lines = selct.getElementsByTagName('option');
			var tlines = lines.length;

			var checkeds = 0;

			for(x = 0; x < tlines; x++){
				if(lines[x].value === val){
					lines[x].selected = false;
					Boss.getById('id-'+elemtString+'-'+x).checked = false;
				}

				if(lines[x].value !== '' && lines[x].selected === true){
					checkeds = checkeds + 1;
				}
			}

			if(checkeds < 1){

				for(x = 0; x < tlines; x++){

					if(lines[x].value === ''){
						lines[x].selected = true;
						Boss.getById('id-'+elemtString+'-').checked = true;
					}
				}

			}else{

				for(x = 0; x < tlines; x++){
					if(lines[x].value === ''){
						lines[x].selected = false;
						Boss.getById('id-'+elemtString+'-').checked = false;
					}
				}
			}

		},
		init: function(elemtString, selctString, confObj){

			var initShow = false;
			var all = 'all';
			var add = 'add';
			var addXHR = false;
			var clear = 'clear';
			var ok = 'ok';
			var placeholder = 'search';

			if(typeof(elemtString) !== 'string'){
				console.warn('Hey stupid, the first parameter need be a string.');
				return false;
			}

			if(typeof(confObj) !== 'object'){
				console.warn('Hey stupid, the thirty parameter need be a object.');
				return false;
			}

			var elemt = Boss.getById(elemtString);

			if(typeof(selctString) !== 'string'){
				console.warn('Hey stupid, the second parameter need be a string.');
				return false;
			}

			var selct = Boss.getById(selctString);
			selct.setAttribute('tabindex', '-1');
			if(selct.nodeName !== 'SELECT' && selct.nodeName !== 'select'){
				console.warn('Hey stupid, the second parameter need be a select field.');
				return false;
			}

			/* FIX TABINDEX IN THE COMPONENT */
			elemt.setAttribute('tabindex', '0');

			/* CALL CHANGE */
			if(confObj.callChange){

				Boss.evts.add('change', elemt, function(evts){
					var targt = Boss.targt(evts);
					if(targt === elemt){
						confObj.callChange(evts);
					}
				});
			}

			if(confObj.initShow){
				initShow = confObj.initShow;
			}
			if(confObj.all){
				all = confObj.all;
			}

			if(confObj.addXHR){
				addXHR = true;
			}

			if(confObj.clear){
				clear = confObj.clear;
			}
			if(confObj.ok){
				ok = confObj.ok;
			}
			if(confObj.placeholder){
				placeholder = confObj.placeholder;
			}

			/* RENDER HTML OF COMPONENT */
			var mask = '<div id="showhide-'+elemtString+'" class="boss-js-select-label no-select"><div>'+confObj.label+' <span class="boss-showhide-triangle animation"></span></div></div>';

			if(initShow === true){
				mask += '<div id="target-showhide-'+elemtString+'" class="boss-js-select-label-show">';
			}
			if(initShow === false){
				mask += '<div id="target-showhide-'+elemtString+'" class="boss-js-select-label-show hidden">';
			}

			/* MASK OF THIS CONPONENT */
			mask += '	<div class="boss-js-select-search"><div><input tabindex="-1" class="boss-js-select-search-input" id="search-'+elemtString+'" type="text" placeholder="'+placeholder+'" /><span class="hidden" id="search-clear-'+elemtString+'">+</span></div></div>';
			mask += '	<div class="boss-js-select-check-all no-select">';
			mask += '		<input type="checkbox" tabindex="-1" class="boss-comp-checkbox" id="all-'+elemtString+'"><label for="all-'+elemtString+'"><span class="boss-comp-checkbox-span"></span>'+all+'</label>';
			mask += '		<div id="ok-'+elemtString+'" class="button">'+ok+'</div>';
			mask += '		<div id="clear-'+elemtString+'" class="button">'+clear+'</div>';
			if(addXHR !== false){
				mask += '		<div id="add-'+elemtString+'" class="hidden button">'+add+'</div>';
			}
			mask += '	</div>';
			mask += '	<div id="list-'+elemtString+'" class="boss-js-select-list no-select" tabindex="-1">';
			mask += '		<ul></ul>';
			mask += '	</div>';
			mask += '	<div class="boss-js-select-status" id="status-'+elemtString+'">status</div>';
			mask += '</div>';

			var tempElemt = document.createElement('div');
			tempElemt.innerHTML = mask;

			elemt.appendChild(tempElemt);

			/* RENDER LIST */
			var ul = Boss.getById('list-'+elemtString).getElementsByTagName('ul')[0];
			var lines = selct.getElementsByTagName('option');
			var tlines = lines.length;

			var tcheckd = 0;

			for(x = 0; x < tlines; x++){

				var li = document.createElement('li');
				li.setAttribute('data-value', lines[x].innerHTML);
				var id = 'id-'+elemtString+'-'+lines[x].value;
				var checkd = '';
				if(lines[x].selected){
					checkd = 'checked';
					tcheckd = tcheckd + 1;
				}
				li.innerHTML = '<input tabindex="-1" '+checkd+' type="checkbox" class="boss-comp-checkbox" value="'+lines[x].value+'" id="'+id+'"><label for="'+id+'"><span class="boss-comp-checkbox-span"></span>'+lines[x].innerHTML+'</label>';
				ul.appendChild(li);

			}

			var stats = Boss.getById('status-'+elemtString);
			stats.textContent = (tlines - 1)+' linhas, '+tcheckd+' marcados';

			/* CLEAR SEARCH */
			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('search-clear-'+elemtString), function(evnt){
				Boss.getById('search-'+elemtString).value = '';
				Boss.trigger('keyup', Boss.getById('search-'+elemtString));
			});

			/* SEARCH EVENTs */
			Boss.evts.add('keyup', Boss.getById('search-'+elemtString), function(evnt){
				/* TIME DELAY AFTER LAST DIGIT PRESSED */
				Boss.delayPersistent2(function(){

					var search = Boss.targt(evnt);
					var lis = ul.getElementsByTagName('li');
					var tlis = lis.length;
					var exp = new RegExp(search.value, 'i');

					var found = 0;

					for(x = 0; x < tlis; x++){
						if(typeof(lis[x].childNodes) !== 'undefined'){
							var tx = lis[x].getAttribute('data-value');
							if(exp.test(tx)){
								lis[x].classList.remove('hidden');
								found = found + 1;
							}else{
								lis[x].classList.add('hidden');
							}
						}
					}

					if(search.value.length > 1){
						if(addXHR !== false){
							Boss.getById('add-'+elemtString).classList.remove('hidden');
						}
						Boss.getById('search-clear-'+elemtString).classList.remove('hidden');
					}else{
						if(addXHR !== false){
							Boss.getById('add-'+elemtString).classList.add('hidden');
						}
						if(Boss.getById('search-clear-'+elemtString)){
							Boss.getById('search-clear-'+elemtString).classList.add('hidden');
						}
					}

				}, 650, 'search-'+elemtString);
			});

			/* CHECKBOX EVENTs */
			Boss.evts.add('change', Boss.getById(elemtString), function(evnt){

				Boss.delay(function(){

					var all = Boss.getById('all-'+elemtString);
					var checkboxs = ul.getElementsByTagName('input');
					var lengt = checkboxs.length;
					var checkeds = 0;
					var selct = Boss.getById(selctString);
					
					for(x = 0; x < lengt; x++){
						/* IGNORE THE FIRST */
						if(x > 0){
							if(checkboxs[x].checked === true){
								checkeds = checkeds + 1;
							}
						}
						if(checkboxs[x].checked === true){
							selct.options[x].selected = true;
						}else{
							selct.options[x].selected = false;
						}
					}

					var stats = Boss.getById('status-'+elemtString);
					stats.textContent = lengt+' linhas, '+checkeds+' marcados';

					if((lengt - 1) <= checkeds){
						all.checked = true;
					}else{
						all.checked = false;
						/* FORCE THE VALUE="" ELEMENT KEEP CHECKED */
					}

					if(checkeds < 1){
						checkboxs[0].checked = true;
					}else{
						checkboxs[0].checked = false;
					}

				}, 10);

			});

			/* ALL BUTTON */
			Boss.evts.add('change', Boss.getById('all-'+elemtString), function(evnt){

				var all = Boss.targt(evnt);

				var checkboxs = ul.getElementsByTagName('input');
				var tcheckboxs = checkboxs.length;
				var selct = Boss.getById(selctString);

				for(x = 0; x < tcheckboxs; x++){
					if(typeof(checkboxs[x].childNodes) !== 'undefined'){

						if(all.checked === true){
							if(selct.options[x].value == ''){
								checkboxs[x].checked = false;
								selct.options[x].selected = false;
							}else{
								checkboxs[x].checked = true;
								selct.options[x].selected = true;
							}
						}else{
							if(selct.options[x].value == ''){
								checkboxs[x].checked = true;
								selct.options[x].selected = true;
							}else{
								checkboxs[x].checked = false;
								selct.options[x].selected = false;
							}
						}
					}
				}
			});

			/* CLEAR BUTTON */
			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('clear-'+elemtString), function(evnt){

				var checkboxs = ul.getElementsByTagName('input');
				var tcheckboxs = checkboxs.length;
				var selct = Boss.getById(selctString);

				var all = Boss.getById('all-'+elemtString);
				all.checked = false;

				for(x = 0; x < tcheckboxs; x++){
					if(typeof(checkboxs[x].childNodes) !== 'undefined'){

						if(checkboxs[x].value == ''){
							checkboxs[x].checked = true;
							selct.options[x].selected = true;
						}else{
							checkboxs[x].checked = false;
							selct.options[x].selected = false;
						}
					}
				}
				Boss.getById('search-'+elemtString).value = '';
				Boss.trigger('keyup', Boss.getById('search-'+elemtString));
			});

			/* ADD BUTTON */
			if(addXHR !== false){
				if(confObj.addXHR){
					Boss.evts.add(Boss.evtTouchUp(), Boss.getById('add-'+elemtString), function(evnt){
						
						Boss.getById('add-'+elemtString).classList.add('hidden');
						Boss.ajax({
							url: confObj.addXHR.url,
							data: {'value': Boss.getById('search-'+elemtString).value, 'name': elemtString},
							dataType: 'json',
							done: function(a){
								if(a.stats === true){

									var li = document.createElement('li');
									li.setAttribute('data-value', a.label);
									var id = 'id-'+elemtString+'-'+a.value;

									li.innerHTML = '<input tabindex="-1" checked type="checkbox" class="boss-comp-checkbox" value="'+a.value+'" id="'+id+'"><label for="'+id+'"><span class="boss-comp-checkbox-span"></span>'+a.label+'</label>';
									ul.appendChild(li);

									var opt = document.createElement('option');
									opt.value = a.value;
									opt.setAttribute('selected', 'selected');
									opt.innerHTML = a.label;

									selct.appendChild(opt);

									Boss.getById('search-'+elemtString).value = '';

									Boss.trigger('keyup', Boss.getById('search-'+elemtString));

								}
								if(a.stats === false){

									var stats = Boss.getById('status-'+elemtString);
									stats.innerHTML = '<span class="red">'+a.error+'</span>';

								}
							}
						});
					});
				}
			}

			/* OK BUTTON */
			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('ok-'+elemtString), function(evnt){

				var elemt = Boss.getById(elemtString);
				var spn = elemt.getElementsByTagName('span')[0];
				var elemtShowHide = Boss.getById('target-showhide-'+elemtString);

				elemtShowHide.classList.add('hidden');
				spn.classList.remove('rotate-180');

				Boss.trigger('change', Boss.getById(elemtString));
				Boss.trigger('blur', Boss.getById(elemtString));
				Boss.getById('search-'+elemtString).value = '';
				Boss.trigger('keyup', Boss.getById('search-'+elemtString));

			});

			/* ACTION IN CONPONENT */
			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('showhide-'+elemtString), function(evnt){

				var elemt = Boss.getById(elemtString);
				var spn = elemt.getElementsByTagName('span')[0];
				var elemtShowHide = Boss.getById('target-showhide-'+elemtString);
				
				/* FIX BUG AT FIRST FUCOS */
				if(elemt.getAttribute('data-last-evt') === 'focus'){
					elemt.setAttribute('data-last-evt', 'up');
				}else{
					if(elemtShowHide.classList.contains('hidden')){
						elemtShowHide.classList.remove('hidden');
						spn.classList.add('rotate-180');
					}else{
						elemtShowHide.classList.add('hidden');
						spn.classList.remove('rotate-180');
					}
				}
			});

			/* BLUR */
			Boss.evts.add('blur', elemt, function(evts){

				var elemt = Boss.getById(elemtString);
				elemt.setAttribute('data-last-evt', 'blur');
				var spn = elemt.getElementsByTagName('span')[0];


				/* IF THE BLUR EVENT WAS HAPPENED OUT THE COMPONENT */
				if(Boss.focusOut(elemt, evts.relatedTarget) === false){

					Boss.getById('target-showhide-'+elemtString).classList.add('hidden');
					spn.classList.remove('rotate-180');
				}

			});

			/* FOCUS */
			Boss.evts.add('focus', elemt, function(evts){

				var elemt = Boss.getById(elemtString);
				elemt.setAttribute('data-last-evt', 'focus');

				var spn = elemt.getElementsByTagName('span')[0];

				Boss.getById('target-showhide-'+elemtString).classList.remove('hidden');
				spn.classList.add('rotate-180');

			});
		}
	},
	selectUnique: {
		data: {},
		setValue: function(elemtString, selctString, val){

			var elemt = Boss.getById(elemtString);
			var rads = Boss.getName(elemtString);
			var labl = Boss.getById('label-'+elemtString);

			var selct = Boss.getById(selctString);
			var tlines = selct.options.length;

			for(x = 0; x < tlines; x++){
				if(selct.options[x].value == val){
					selct.options[x].selected = true;
					rads[x].checked = true;
					labl.textContent = selct.options[x].textContent;
				}else{
					rads[x].checked = false;
				}
			}
		},

		setAdd: function(elemtString, selctString, val, label){

			var id = 'id-'+elemtString+'-'+val;

			if(!Boss.getById(id)){

				var list = Boss.getById('list-'+elemtString);
				var ul = list.querySelector('ul');

				var select = Boss.getById(selctString);

				var option = document.createElement('option');
				option.textContent = label;
				option.setAttribute('value', val);

				select.appendChild(option);

				var li = document.createElement('li');
				li.setAttribute('data-value', label);

				li.innerHTML = '<input tabindex="-1" type="radio" name="'+elemtString+'" class="boss-comp-radio" value="'+val+'" id="'+id+'"><label for="'+id+'"><span class="boss-comp-radio-span"></span>'+label+'</label>';

				ul.appendChild(li);
			}

		},
		unsetValue: function(elemtString, selctString, val){

			var elemt = Boss.getById(elemtString);
			var selct = Boss.getById(selctString);
			var labl = Boss.getById('label-'+elemtString);

			var ul = Boss.getById('list-'+elemtString).getElementsByTagName('ul')[0];
			var lines = selct.getElementsByTagName('option');
			var tlines = lines.length;

			var checkeds = 0;

			for(x = 0; x < tlines; x++){
				if(lines[x].value === val){
					lines[x].selected = false;
					Boss.getById('id-'+elemtString+'-'+x).checked = false;
				}

				if(lines[x].value !== '' && lines[x].selected === true){
					checkeds = checkeds + 1;
				}
			}

			if(checkeds < 1){

				for(x = 0; x < tlines; x++){

					if(lines[x].value === ''){
						lines[x].selected = true;
						Boss.getById('id-'+elemtString+'-').checked = true;
					}
				}

			}else{

				for(x = 0; x < tlines; x++){
					if(lines[x].value === ''){
						lines[x].selected = false;
						Boss.getById('id-'+elemtString+'-').checked = false;
					}
				}
			}

			labl.textContent = selct.options[selct.selectedIndex].textContent;

		},
		init: function(elemtString, selctString, confObj){

			var initShow = false;
			var add = 'add';
			var addXHR = false;
			var searchXHR = false;
			var clear = 'clear';
			var ok = 'ok';
			var placeholder = 'search';

			if(typeof(elemtString) !== 'string'){
				console.warn('Hey stupid, the first parameter need be a string.');
				return false;
			}

			if(typeof(confObj) !== 'object'){
				console.warn('Hey stupid, the thirty parameter need be a object.');
				return false;
			}

			var elemt = Boss.getById(elemtString);

			if(typeof(selctString) !== 'string'){
				console.warn('Hey stupid, the second parameter need be a string.');
				return false;
			}

			var selct = Boss.getById(selctString);
			selct.setAttribute('tabindex', '-1');
			if(selct.nodeName !== 'SELECT' && selct.nodeName !== 'select'){
				console.warn('Hey stupid, the second parameter need be a select field.');
				return false;
			}

			/* FIX TABINDEX IN THE COMPONENT */
			elemt.setAttribute('tabindex', '0');

			/* CALL CHANGE */
			if(confObj.callChange){

				Boss.evts.add('change', elemt, function(evts){
					var targt = Boss.targt(evts);
					if(targt === elemt){
						confObj.callChange(evts);
					}
				});
			}
			if(confObj.initShow){
				initShow = confObj.initShow;
			}
			if(confObj.addXHR){
				addXHR = true;
			}
			if(confObj.searchXHR){
				searchXHR = true;
			}
			if(confObj.clear){
				clear = confObj.clear;
			}
			if(confObj.ok){
				ok = confObj.ok;
			}
			if(confObj.placeholder){
				placeholder = confObj.placeholder;
			}

			/* RENDER HTML OF COMPONENT */
			var mask = '<div id="showhide-'+elemtString+'" class="boss-js-select-label no-select"><div><div id="label-'+elemtString+'"></div> <span class="boss-showhide-triangle animation"></span></div></div>';

			if(initShow === true){
				mask += '<div id="target-showhide-'+elemtString+'" class="boss-js-select-label-show">';
			}
			if(initShow === false){
				mask += '<div id="target-showhide-'+elemtString+'" class="boss-js-select-label-show hidden">';
			}

			/* MASK OF THIS CONPONENT */
			mask += '	<div class="boss-js-select-search"><div><input tabindex="-1" class="boss-js-select-search-input" id="search-'+elemtString+'" type="text" placeholder="'+placeholder+'" /><span class="hidden" id="search-clear-'+elemtString+'">+</span></div></div>';
			mask += '	<div class="boss-js-select-check-all no-select">';
			mask += '		<div id="ok-'+elemtString+'" class="button">'+ok+'</div>';
			mask += '		<div id="clear-'+elemtString+'" class="button">'+clear+'</div>';
			if(addXHR !== false){
				mask += '		<div id="add-'+elemtString+'" class="hidden button">'+add+'</div>';
			}
			mask += '	</div>';
			mask += '	<div id="list-'+elemtString+'" class="boss-js-select-list no-select" tabindex="-1">';
			mask += '		<ul></ul>';
			mask += '	</div>';
			mask += '	<div class="boss-js-select-status" id="status-'+elemtString+'">status</div>';
			mask += '</div>';

			var tempElemt = document.createElement('div');
			tempElemt.innerHTML = mask;

			elemt.appendChild(tempElemt);

			/* RENDER LIST */
			var ul = Boss.getById('list-'+elemtString).getElementsByTagName('ul')[0];
			var lines = selct.getElementsByTagName('option');
			var tlines = lines.length;

			var tcheckd = 0;
			for(x = 0; x < tlines; x++){

				var li = document.createElement('li');
				li.setAttribute('data-value', lines[x].innerHTML);

				if(lines[x].getAttribute('data-class')){
					li.classList.add(lines[x].getAttribute('data-class'));
				}

				var id = 'id-'+elemtString+'-'+lines[x].value;
				var checkd = '';
				if(lines[x].selected){
					checkd = 'checked';
					tcheckd = tcheckd + 1;
				}

				li.innerHTML = '<input tabindex="-1" '+checkd+' type="radio" name="'+elemtString+'" class="boss-comp-radio" value="'+lines[x].value+'" id="'+id+'">\
					<label for="'+id+'"><span class="boss-comp-radio-span"></span>'+lines[x].innerHTML+'</label>';

				ul.appendChild(li);

			}

			/* LABEL WRITE */
			var labl = Boss.getById('label-'+elemtString);
			labl.textContent = selct.options[selct.selectedIndex].textContent;

			var stats = Boss.getById('status-'+elemtString);
			stats.textContent = (tlines - 1)+' linhas, '+tcheckd+' marcados';

			/* CLEAR SEARCH */
			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('search-clear-'+elemtString), function(evnt){
				Boss.getById('search-'+elemtString).value = '';
				Boss.trigger('keyup', Boss.getById('search-'+elemtString));
			});

			/* SEARCH EVENTs */
			Boss.evts.add('keyup', Boss.getById('search-'+elemtString), function(evnt){
				/* TIME DELAY AFTER LAST DIGIT PRESSED */
				Boss.delayPersistent2(function(){

					var search = Boss.targt(evnt);
					var lis = ul.getElementsByTagName('li');
					var tlis = lis.length;
					var exp = new RegExp(search.value, 'i');

					
					if(searchXHR === true && search.value.length > 2){
						Boss.ajax({
							'url': confObj.searchXHR,
							'data': {'label': search.value},
							'dataType': 'json',
							'done': function(rtn){

								var lengt = Object.keys(rtn).length;

								var saveLabel = '';
								var saveValue = '';
								/* SAVE SELECTED */
								if(selct.value !== ''){
									saveLabel = selct.options[selct.selectedIndex].textContent;
									saveValue = selct.value;
								}

								/* REMOVE OPTIONS AND LIs */
								ul.innerHTML = '<li data-value=" - - "><input tabindex="-1" type="radio" name="'+elemtString+'" class="boss-comp-radio" value="" id="'+id+'"><label for="'+id+'"><span class="boss-comp-radio-span"></span> - - </label></li>';
								selct.innerHTML = '<option value=""> - - </option>';
								
								/* INSERT SAVED SELECTED */
								if(saveLabel.value !== '' && saveValue !== ''){

									var li = document.createElement('li');
									li.setAttribute('data-value', saveLabel);
									var id = 'id-'+elemtString+'-'+saveValue;

									li.innerHTML = '<input checked tabindex="-1" type="radio" name="'+elemtString+'" class="boss-comp-radio" value="'+saveValue+'" id="'+id+'"><label for="'+id+'"><span class="boss-comp-radio-span"></span>'+saveLabel+'</label>';
									ul.appendChild(li);

									var opt = document.createElement('option');
									opt.value = saveValue;
									opt.setAttribute('selected', 'selected');
									opt.innerHTML = saveLabel;

									selct.appendChild(opt);
								}

								/* SAVE found */
								for(x = 0; x < lengt; x++){

									if(saveValue != rtn[x].value){

										var li = document.createElement('li');
										li.setAttribute('data-value', rtn[x].label);
										var id = 'id-'+elemtString+'-'+rtn[x].value;

										li.innerHTML = '<input tabindex="-1" type="radio" name="'+elemtString+'" class="boss-comp-radio" value="'+rtn[x].value+'" id="'+id+'"><label for="'+id+'"><span class="boss-comp-radio-span"></span>'+rtn[x].label+'</label>';
										ul.appendChild(li);

										var opt = document.createElement('option');
										opt.value = rtn[x].value;
										opt.innerHTML = rtn[x].label;

										selct.appendChild(opt);

									}
								}
							}
						});
					}else{
						var found = 0;
						for(x = 0; x < tlis; x++){
							if(typeof(lis[x].childNodes) !== 'undefined'){
								var tx = lis[x].getAttribute('data-value');
								if(exp.test(tx)){
									lis[x].classList.remove('hidden');
									found = found + 1;
								}else{
									lis[x].classList.add('hidden');
								}
							}
						}

						if(search.value.length > 1){
							if(addXHR !== false){
								Boss.getById('add-'+elemtString).classList.remove('hidden');
							}
							Boss.getById('search-clear-'+elemtString).classList.remove('hidden');
						}else{
							if(addXHR !== false){
								Boss.getById('add-'+elemtString).classList.add('hidden');
							}

							if(Boss.getById('search-clear-'+elemtString)){
								Boss.getById('search-clear-'+elemtString).classList.add('hidden');
							}
						}
					}

				}, 650, 'search-'+elemtString);
			});

			/* CHECKBOX EVENTs */
			Boss.evts.add('change', Boss.getById(elemtString), function(evnt){

				Boss.delay(function(){

					var checkboxs = ul.getElementsByTagName('input');
					var lengt = checkboxs.length;
					var checkeds = 0;
					var selct = Boss.getById(selctString);
					var labl = Boss.getById('label-'+elemtString);
					
					for(x = 0; x < lengt; x++){
						/* IGNORE THE FIRST */
						if(x > 0){
							if(checkboxs[x].checked === true){
								checkeds = checkeds + 1;
							}
						}
						if(checkboxs[x].checked === true){
							selct.options[x].selected = true;
						}else{
							selct.options[x].selected = false;
						}
					}

					var stats = Boss.getById('status-'+elemtString);
					stats.textContent = lengt+' linhas, '+checkeds+' marcados';

					if(checkeds < 1){
						checkboxs[0].checked = true;
					}else{
						checkboxs[0].checked = false;
					}

					labl.textContent = selct.options[selct.selectedIndex].textContent;

				}, 10);

			});

			/* CLEAR BUTTON */
			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('clear-'+elemtString), function(evnt){

				var checkboxs = ul.getElementsByTagName('input');
				var tcheckboxs = checkboxs.length;
				var selct = Boss.getById(selctString);
				var labl = Boss.getById('label-'+elemtString);

				for(x = 0; x < tcheckboxs; x++){
					if(typeof(checkboxs[x].childNodes) !== 'undefined'){

						if(checkboxs[x].value == ''){
							checkboxs[x].checked = true;
							selct.options[x].selected = true;
						}else{
							checkboxs[x].checked = false;
							selct.options[x].selected = false;
						}
					}
				}
				Boss.getById('search-'+elemtString).value = '';
				Boss.trigger('keyup', Boss.getById('search-'+elemtString));
			
				labl.textContent = selct.options[selct.selectedIndex].textContent;
			});

			/* ADD BUTTON */
			if(addXHR !== false){
				if(confObj.addXHR){
					Boss.evts.add(Boss.evtTouchUp(), Boss.getById('add-'+elemtString), function(evnt){
						
						Boss.getById('add-'+elemtString).classList.add('hidden');
						Boss.ajax({
							url: confObj.addXHR.url,
							data: {'value': Boss.getById('search-'+elemtString).value, 'name': elemtString},
							dataType: 'json',
							done: function(a){
								if(a.stats === true){

									var li = document.createElement('li');
									li.setAttribute('data-value', a.label);
									var id = 'id-'+elemtString+'-'+a.value;

									li.innerHTML = '<input checked tabindex="-1" type="radio" name="'+elemtString+'" class="boss-comp-radio" value="'+a.value+'" id="'+id+'"><label for="'+id+'"><span class="boss-comp-radio-span"></span>'+a.label+'</label>';
									ul.appendChild(li);

									var opt = document.createElement('option');
									opt.value = a.value;
									opt.setAttribute('selected', 'selected');
									opt.innerHTML = a.label;

									selct.appendChild(opt);

									Boss.getById('search-'+elemtString).value = '';

									Boss.trigger('keyup', Boss.getById('search-'+elemtString));

								}
								if(a.stats === false){

									var stats = Boss.getById('status-'+elemtString);
									stats.innerHTML = '<span class="red">'+a.error+'</span>';

								}
							}
						});
					});
				}
			}

			/* OK BUTTON */
			Boss.evts.add('click', Boss.getById('ok-'+elemtString), function(evnt){

				var elemt = Boss.getById(elemtString);
				var spn = elemt.getElementsByTagName('span')[0];
				var elemtShowHide = Boss.getById('target-showhide-'+elemtString);
				var labl = Boss.getById('label-'+elemtString);

				elemtShowHide.classList.add('hidden');
				spn.classList.remove('rotate-180');

				Boss.trigger('change', Boss.getById(elemtString));
				Boss.trigger('change', Boss.getById(selctString));
				Boss.trigger('blur', Boss.getById(elemtString));
				Boss.getById('search-'+elemtString).value = '';
				Boss.trigger('keyup', Boss.getById('search-'+elemtString));
			
				labl.textContent = selct.options[selct.selectedIndex].textContent;

			});

			/* DBLCLICK CHECKBOX */
			Boss.evts.add('dblclick', ul, function(evnt){
				Boss.delay(function(){
					Boss.trigger('click', Boss.getById('ok-'+elemtString));
				}, 10);
			});

			/* ACTION IN CONPONENT */
			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('showhide-'+elemtString), function(evnt){

				var elemt = Boss.getById(elemtString);
				var spn = elemt.getElementsByTagName('span')[0];
				var elemtShowHide = Boss.getById('target-showhide-'+elemtString);
				
				/* FIX BUG AT FIRST FUCOS */
				if(elemt.getAttribute('data-last-evt') === 'focus'){
					elemt.setAttribute('data-last-evt', 'up');
				}else{
					if(elemtShowHide.classList.contains('hidden')){
						elemtShowHide.classList.remove('hidden');
						spn.classList.add('rotate-180');
					}else{
						elemtShowHide.classList.add('hidden');
						spn.classList.remove('rotate-180');
					}
				}
			});

			/* BLUR */
			Boss.evts.add('blur', elemt, function(evts){

				var elemt = Boss.getById(elemtString);
				elemt.setAttribute('data-last-evt', 'blur');
				var spn = elemt.getElementsByTagName('span')[0];

				/* IF THE BLUR EVENT WAS HAPPENED OUT THE COMPONENT */
				if(Boss.focusOut(elemt, evts.relatedTarget) === false){

					Boss.getById('target-showhide-'+elemtString).classList.add('hidden');
					spn.classList.remove('rotate-180');
				}

			});

			/* FOCUS */
			Boss.evts.add('focus', elemt, function(evts){

				var elemt = Boss.getById(elemtString);
				elemt.setAttribute('data-last-evt', 'focus');

				var spn = elemt.getElementsByTagName('span')[0];

				Boss.getById('target-showhide-'+elemtString).classList.remove('hidden');
				spn.classList.add('rotate-180');

			});
		}
	},
	/* ADD EVENTS */
	evts: {
		add: function (evt, el, fn) {
			if(el !== null){
				if(window.addEventListener){
					el.addEventListener(evt, function(evt){
						fn(evt);
					}, true);
				}else{
					el.attachEvent("on"+evt, function(){
						fn(evt);
					});
				}
			}
		}
	},
	trigger: function (ev, el){
		if(document.createEvent){
			evento = document.createEvent('HTMLEvents');
			evento.initEvent(ev, true, true);
			el.dispatchEvent(evento);
		}else{
			evento = document.createEventObject();
			el.fireEvent('on'+ev, evento);
		}
	},
	getById: function(element){
		return document.getElementById(element);
	},
	getName: function(element){
		return document.getElementsByName(element);
	},
	remover: function(el){
		el.parentNode.removeChild(el);
	},
	targt: function (e) {
		e = e.target ? e : window.event;
		return e.target || e.srcElement;
	},
	inArray: function(st, arr){
		var lengt = arr.length;
		var x;
		for(x = 0; x < lengt; x++){
			if(arr[x] === st){
				return true;
				break;
			}
		}
		return false;
	},
	isChild: function(parent, child) {
		if(child.parentNode){
			var node = child.parentNode;
			while(node !== null){
				if(node === parent){
					return true;
				}
				node = node.parentNode;
			}
		}
		return false;
	},
	positionAtTop: function(el){
		posicao = 0;
		if(el.offsetParent){
			do{
				posicao += el.offsetTop;
			} while (el = el.offsetParent);
		}
		return posicao;
	},
	insertAfter: function(newElement, targetElement){
		var parent = targetElement.parentNode;
		if(parent.lastchild == targetElement) {
			parent.appendChild(newElement);
		}else{
			parent.insertBefore(newElement, targetElement.nextSibling);
		}
	},
	getUri: function(){
		var lca = window.location.href.split('?');
		var getUri = {};

		if(typeof(lca[1]) !== 'undefined'){
			var parts = lca[1].split('&');
			for (var i = 0; i < parts.length; i++) {
				var temp = parts[i].split('=');
				getUri[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
			}
		}
		return getUri;
	},
	focusOut: function(parnt, chld){
		if(chld){
			var nod = chld.parentNode;
			while (nod !== null) {
				if (nod === parnt) {
					return true;
				}
				nod = nod.parentNode;
			}
			return false;
		}else{
			return false;
		}
	},
	toBytes: function(lengt){
		if(lengt < 1024){
			var siz = lengt.toFixed(1)+'B';
		}else if(lengt >= 1024 && lengt < 1048576){
			var siz = lengt / 1024;
			var siz = siz.toFixed(1)+'KB';
		}else{
			var siz = lengt / 1048576;
			var siz = siz.toFixed(1)+'MB';
		}
		return siz;
	},
	serializer: function(formid){
		if(this.getById(formid)){
			var form = this.getById(formid);
			var inputs =  form.getElementsByTagName('input');
			var selects = form.getElementsByTagName('select');
			var textareas = form.getElementsByTagName('textarea');
			ti = inputs.length;
			ts = selects.length;
			tt = textareas.length;
			serializado = {};
			for(it = 0; it < ti; it++){
				if(inputs[it].name && !inputs[it].getAttribute('disabled')){
					if(inputs[it].getAttribute('type') === 'radio' || inputs[it].getAttribute('type') === 'checkbox'){
						if(inputs[it].checked === true){
							serializado[inputs[it].getAttribute('name')] = inputs[it].value;
						}
					}else{
						serializado[inputs[it].getAttribute('name')] = inputs[it].value;
					}
				}
			}
			for(st = 0; st < ts; st++){
				if(selects[st].getAttribute('name') && !selects[st].getAttribute('disabled')){
					serializado[selects[st].getAttribute('name')] = selects[st].value;
				}
			}
			for(t = 0; t < tt; t++){
				if(textareas[t].getAttribute('name') && !textareas[t].getAttribute('disabled')){
					serializado[textareas[t].getAttribute('name')] = textareas[t].value;
				}
			}
			return serializado;
		}else{
			return false;
		}
	},
	screensizes: function(){

		var orient;

		if(window.screen.mozOrientation){
			orient = window.screen.mozOrientation;
		}else if(window.screen.msOrientation){
			orient = window.screen.msOrientation
		}else if(window.screen.orientation){
			if(window.screen.orientation.type){
				orient = window.screen.orientation.type;
			}else{
				orient = window.screen.orientation;
			}
		}else{
			if(window.screen.height > window.screen.width){
				orient = 'portrait-primary';
			}else{
				orient = 'landscape-primary';
			}
		}

		return {
			'viewWidth': window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
			'viewHeight': window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
			'pageWidth': document.body.clientWidth || document.body.offsetWidth,
			'pageHeight': document.body.clientHeight || document.body.offsetHeight,
			'resolutionWidth': window.screen.width,
			'resolutionHeight': window.screen.height,
			'orientation': orient,
			'colorDepth': window.screen.colorDepth,
			'pixelDepth': window.screen.pixelDepth
		}

	},
	ajax: function (options) {
		var XHR;
		var strPost = new Array();
		var r20 = /%20/g;

		if(window.XMLHttpRequest){
			XHR = new XMLHttpRequest();
		}else if(window.ActiveXObject){
			XHR = new ActiveXObject('Msxml2.XMLHTTP');
			if(!XHR){
				XHR = new ActiveXObject('Microsoft.XMLHTTP');
			}
		}else{
			console.warn('This Browser do not support XMLHttpRequest');
			return false;
		}

		if(options.progress){
			XHR.upload.addEventListener('progress', options.progress, false);
		}

		if(options.error){
			XHR.addEventListener('error', options.error, false);
			XHR.addEventListener('abort', options.error, false);
		}

		XHR.open('POST', options.url, true);

		/* AS DATA */
		if(options.data){

			XHR.setRequestHeader("Content-type","application/x-www-form-urlencoded;charset=UTF-8");

			for(x in options.data){
				strPost.push(encodeURIComponent(x)+'='+encodeURIComponent(options.data[x]));
			}

			stPost = strPost.join('&').replace(r20, "+");
			XHR.send(stPost);

		/* AS FORM */
		}else if(options.formId){

			var form = Boss.getById(options.formId);
			var frm = new FormData();

			var inputs = form.getElementsByTagName('input');
			var tInputs = inputs.length;

			for(x = 0; x < tInputs; x++){
				if(inputs[x].getAttribute('name') && !inputs[x].getAttribute('disabled')){

					/* INPUT FILE */
					if(inputs[x].type === 'file'){
						
						var tFiles = inputs[x].files.length;

						if(tFiles > 0){
							for(z = 0; z < tFiles; z++){
								var fle = inputs[x].files[z];
								frm.append(inputs[x].getAttribute('name'), fle);
							}
						}

					/* RADIO AND CHECKBOX */
					}else if(inputs[x].type === 'radio' || inputs[x].type === 'checkbox'){
						if(inputs[x].checked === true){
							frm.append(inputs[x].getAttribute('name'), inputs[x].value);
						}
					/* OTHERS INPUTS FIELDS */
					}else{
						frm.append(inputs[x].getAttribute('name'), inputs[x].value);
					}
				}
			}

			var textareas = form.getElementsByTagName('textarea');
			var tTextareas = textareas.length;

			for(x = 0; x < tTextareas; x++){
				if(textareas[x].getAttribute('name') && !textareas[x].getAttribute('disabled')){
					frm.append(textareas[x].getAttribute('name'), textareas[x].value);
				}
			}

			var selects = form.getElementsByTagName('select');
			var tSelects = selects.length;

			for(x = 0; x < tSelects; x++){
				if(selects[x].getAttribute('name') && !selects[x].getAttribute('disabled')){
					frm.append(selects[x].getAttribute('name'), selects[x].value);
				}
			}

			XHR.send(frm);

		}

		XHR.onreadystatechange = function(){

			if(XHR.readyState === 4 && (XHR.status === 200 || XHR.status === 304)){
				if(typeof(options.dataType) !== 'undefined'){

					if(options.dataType === 'JSON' || options.dataType === 'json'){
						jsonStr = XHR.responseText;
						if(JSON.parse(XHR.responseText)){
							jsonStr = eval("("+XHR.responseText+")");
						}
						if(typeof(options.done) === 'function'){
							options.done(jsonStr);
						}
					}

				}else{
					if (typeof(options.done) === 'function'){
						options.done(XHR.responseText);
					}
				}
			}

			if(XHR.readyState === 4 && XHR.status === 404){
				if(typeof(options.error) === 'function'){
					options.error(XHR);
				}
			}

			if(XHR.readyState === 4 && XHR.status === 500){
				if(typeof(options.error) === 'function'){
					options.error(XHR);
				}
			}
		}

		return XHR;
	},
	get: function(url, fn){

		var XHR = new XMLHttpRequest();

		XHR.onreadystatechange = function(){
			if (XHR.readyState == 4 && (XHR.status == 200 || XHR.status == 400 || XHR.status === 304)){

				jsonStr = XHR.responseText;
				if(JSON.parse(XHR.responseText)){
					jsonStr = eval("("+XHR.responseText+")");
				}
				if(typeof(fn) === 'function'){
					fn(jsonStr);
				}
			}
		};

		XHR.open("GET", url, true);
		XHR.send();

	},
	pushstate: {
		init: function(configObj){

			history.scrollRestoration = 'manual';

			var xhrfn = function(){};
			var lockChangePageFn = function(){};
			var lockExitMessage = '';

			if(typeof(configObj.xhrfn) === 'function'){
				xhrfn = configObj.xhrfn;
			}

			if(typeof(configObj.lockChangePageFn) === 'function'){
				lockChangePageFn = configObj.lockChangePageFn;
			}

			if(configObj.lockExitMessage){
				lockExitMessage = configObj.lockExitMessage;
			}

			/* POPSTATE EVENT */
			Boss.evts.add('popstate', window, function(evts){

				if(lockChangePage === true){
					lockChangePageFn(window.location.href);
					return false;
				}

				var host = window.location.protocol+'//'+window.location.host;
				var controler = window.location.href.replace(host, '')+'!popstate';
				xhrfn(controler, function(){});

			});

			/* CLICK EVENTS */
			Boss.evts.add('click', document, function(evts){

				var elemt = Boss.targt(evts);

				var expJs = new RegExp('javascript:', 'i');
				var expFTP = new RegExp('ftp:', 'i');
				var expMail = new RegExp('mailto:', 'i');
				var expWhatsapp = new RegExp('whatsapp:', 'i');

				var domain = window.location.hostname;

				if(elemt.parentElement !== null && elemt.nodeName !== 'BUTTON' && elemt.parentElement.nodeName === 'BUTTON'){
					elemt = elemt.parentElement;
				}

				if(elemt.nodeName === 'BUTTON' && elemt.getAttribute('data-href') && elemt.getAttribute('data-href') !== false){

					var hrefDomain = elemt.getAttribute('data-href').replace('http://', '');
					hrefDomain = hrefDomain.replace('https://', '');

					var re = new RegExp('^\/', 'i'); 

					if(re.test(hrefDomain) === true){
						hrefDomain = domain+hrefDomain;
					}

					var urlIn = new RegExp('^'+domain, 'i');

					if(urlIn.test(hrefDomain) === true){
						Boss.pushstate.goXHR(elemt.getAttribute('data-href'), xhrfn, lockChangePageFn);
					}else{
						var a = document.createElement('a');
						a.href = elemt.getAttribute('data-href');
						Boss.trigger('click', a);
					}
				}else{

					var wl = true;
					while(wl === true){

						if(elemt.parentNode !== null && elemt.nodeName !== 'A'){
							elemt = elemt.parentNode;
						}else{
							wl = false;

							if(elemt.href){

								var hrefDomain = elemt.href.replace('http://', '');
								hrefDomain = hrefDomain.replace('https://', '');

								var urlIn = new RegExp('^'+domain, 'i');

								if(urlIn.test(hrefDomain) === true && !elemt.getAttribute('data-href')){

									/* GOXHR*/
									if(expJs.test(elemt.href) === false || 
										expFTP.test(elemt.href) === false || 
										expMail.test(elemt.href) === false || 
										expWhatsapp.test(elemt.href) === false || 
										!elemt.getAttribute('data-href')){

										if(evts.stopPropagation){
											evts.stopPropagation();
										}
										if(evts.preventDefault){
											evts.preventDefault();
										}
										Boss.pushstate.goXHR(elemt.href, xhrfn, lockChangePageFn);
									}

								}
							}
						}
					}
				}
			});

			/* beforeunload EVENT  */
			Boss.evts.add('beforeunload', window, function(evts){
				if(lockClosePage === true){

					evts.cancelBubble = true;

					evts.returnValue = lockExitMessage;

					if(evts.stopPropagation){
						evts.stopPropagation();
					}

					if(evts.preventDefault){
						evts.preventDefault();
					}

					return lockExitMessage;
				}
			});
		},
		goXHR: function(controler, xhrfn, lockChangePageFn){

			if(lockChangePage === true && lockChangePageFn){
				lockChangePageFn(controler);
				return false;
			}

			var host = window.location.protocol+'//'+window.location.host;
			var ctrlpage = window.location.href.replace(host, '');
			ctrlpage = ctrlpage.replace(/\?.*$/, '');
			XHRPopStateScroll[ctrlpage] = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

			xhrfn(controler, function(){
				history.pushState({}, '', controler);
			});

		}
	},
	confirm2: function(obj){
		if(obj.message && obj.ok){
			
			var mask = '<div class="boss-confirm"><p class="text-center strong">'+obj.message+'</p><hr /><p class="text-center"><button id="boss-confirm-ok" class="'+obj.okclass+'">'+obj.ok+'</button></p></div>';

			if(obj.no !== false){
				var mask = '<div class="boss-confirm"><p class="text-center strong">'+obj.message+'</p><hr /><p class="text-center"><button id="boss-confirm-ok" class="'+obj.okclass+'">'+obj.ok+'</button> <button id="boss-confirm-no" class="'+obj.noclass+'">'+obj.no+'</button></p></div>';
			}

			if(Boss.getById('boss-confirm')){
				var div = Boss.getById('boss-confirm');
				div.innerHTML = mask;
			}else{
				var div = document.createElement('div');
				div.setAttribute('id', 'boss-confirm');
				div.innerHTML = mask;
				document.body.appendChild(div);
			}

			div.classList.remove('hidden');

			if(Boss.getById('boss-confirm-ok')){
				Boss.getById('boss-confirm-ok').focus();
			}

			document.body.style.overflow = "hidden";

			Boss.evts.add(Boss.evtTouchUp(), div, function(evts){
				if(div === Boss.targt(evts)){
					div.classList.add('hidden');

					if(obj.cancelFunction){
						obj.cancelFunction();
						document.body.style.overflow = null;
					}
				}
			});

			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('boss-confirm-ok'), function(evts){
				div.classList.add('hidden');

				if(obj.okFunction){
					obj.okFunction();
					document.body.style.overflow = null;
				}
			});

			Boss.evts.add('keyup', Boss.getById('boss-confirm-ok'), function(evts){

				if(evts.keyCode == 13){
					div.classList.add('hidden');

					if(obj.okFunction){
						obj.okFunction();
						document.body.style.overflow = null;
					}
				}
			});

			if(obj.noFunction){
				Boss.evts.add(Boss.evtTouchUp(), Boss.getById('boss-confirm-no'), function(evts){
					div.classList.add('hidden');
					obj.noFunction();
					document.body.style.overflow = null;
				});
			}

			Boss.evts.add('keyup', Boss.getById('boss-confirm-no'), function(evts){
				if(evts.keyCode == 13){
					div.classList.add('hidden');
					obj.noFunction();
					document.body.style.overflow = null;
				}
			});
		}
	},
	warning: function(obj){
		if(obj.message){

			var mask = '<div class="boss-warning"><button id="boss-warning-close" type="button" class="boss-warning-close"><i class="icl ic-times"></i></button><p class="text-center font1 strong">'+obj.message+'</p></div>';

			if(Boss.getById('boss-warning')){
				var div = Boss.getById('boss-warning');
				div.innerHTML = mask;
			}else{
				var div = document.createElement('div');
				div.setAttribute('id', 'boss-warning');
				div.innerHTML = mask;
				document.body.appendChild(div);
			}

			if(obj.color){
				div.setAttribute('class', obj.color);
			}else{
				div.removeAttribute('class');
			}

			div.classList.remove('hidden');
			div.style.top = '-1px';
			div.style.opacity = '1';

			Boss.delayPersistent2(function(){
				div.style.top = '-80px';
				div.style.opacity = '0';
			}, 8000, 'warning');

			Boss.evts.add(Boss.evtTouchUp(), Boss.getById('boss-warning-close'), function(){

				if(Boss.getById('boss-warning')){
					
					var div = Boss.getById('boss-warning');
					div.style.top = '-80px';
					div.style.opacity = '0';
				}
			});
		}
	},
	delay: function (fn, tm) {
		window.setTimeout(function () {
			fn();
		}, tm);
	},
	delayPersistent: (function(fn, ms){
		var timer = 0;
		return function(fn, ms){
			clearTimeout(timer);
			timer = setTimeout(fn, ms);
		};
	}()),
	delayPersistent2: (function(fn, ms, label){
		if(typeof(delayPersistent2) === 'undefined'){
			window.delayPersistent2 = {};
		}
		return function(fn, ms, label){
			clearTimeout(delayPersistent2[label]);
			delayPersistent2[label] = setTimeout(fn, ms);
		};
	}()),
	numberFormat: function(n){

		var spt = String(n).split('.');

		var milhar = '0';
		var centavos = '00';
		var valor = '';

		if(spt[0]){
			milhar = spt[0];
			var sptMilahr = milhar.split('');
			var tsptMilahr = sptMilahr.length;
			if(tsptMilahr > 0){
				var tres = 0;
				for(x = (tsptMilahr - 1); x >= 0; x = x-1){
					valor = sptMilahr[x]+valor;
					tres = tres + 1;
					if(tres === 3 && sptMilahr[(x-1)]){
						valor = '.'+valor;
						tres = 0;
					}
				}
			}

		}
		if(spt[1]){
			centavos = spt[1];
		}

		return valor+','+centavos;
	}
};

Boss.validate = {
	rules: {
		notUppercase: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			var upper = {'A':'','B':'','C':'','D':'','E':'','F':'','G':'','H':'','I':'','J':'','K':'','L':'','M':'','N':'','O':'','P':'','Q':'','R':'','S':'','T':'','U':'','V':'','W':'','X':'','Y':'','Z':''};
			var lower = {'a':'','b':'','c':'','d':'','e':'','f':'','g':'','h':'','i':'','j':'','k':'','l':'','m':'','n':'','o':'','p':'','q':'','r':'','s':'','t':'','u':'','v':'','w':'','x':'','y':'','z':''};

			var lengtUpper = 0;
			var lengtLower = 0;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var temp = value.split('');
			var lTemp = temp.length;

			if(lTemp > 4){

				for (x = 0; x < lTemp; x++) {

					if(typeof(upper[temp[x]]) !== 'undefined'){
						lengtUpper = lengtUpper + 1;
					}else if(typeof(lower[temp[x]]) !== 'undefined'){
						lengtLower = lengtLower + 1;
					}else{
						lengtLower = lengtLower + 1;
					}
				}

				// MORE UPPER WHO LOWER
				if(lengtUpper > lengtLower){
					return false;
				}

			}

			return true;

		},
		notSpace: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var temp = value.split('');
			var lTemp = temp.length;

			for (x = 0; x < lTemp; x++) {

				if(temp[x] == ' '){
					return false;
				}
			}

			return true;

		},
		cnpj: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var value = value.replace(/\D/g, "");

			while(value.length < 14){
				value = "0" + value;
			}
			var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
			var a = [];
			var b = 0;
			var c = [6,5,4,3,2,9,8,7,6,5,4,3,2], x;

			for (var i = 0; i < 12; i++){
				a[i] = value.charAt(i);
				b += a[i] * c[i+1];
			}

			if((x = b % 11) < 2){
				a[12] = 0;
			}else{
				a[12] = 11 - x;
			}

			b = 0;
			for (var y = 0; y < 13; y++){
				b += (a[y] * c[y]);
			}

			if ((x = b % 11) < 2){
				a[13] = 0;
			}else{
				a[13] = 11 - x;
			}

			if ((parseInt(value.charAt(12), 10) !== a[12]) || (parseInt(value.charAt(13), 10) !== a[13]) || value.match(expReg) ){
				return false;
			}
			return true;
		},
		cpf: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			value = value.replace(".","");
			value = value.replace(".","");
			var cpf = value.replace("-","");

			var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
			var a = [];
			var b = 0;
			var c = 11;
			var x, y;
			for (var i = 0; i < 11; i++){
				a[i] = cpf.charAt(i);
				if (i < 9){
					b += (a[i] * --c);
				}
			}
			if ((x = b % 11) < 2){
				a[9] = 0;
			}else{
				a[9] = 11-x;
			}

			b = 0;
			c = 11;

			for(y=0; y<10; y++){
				b += (a[y] * c--);
			}

			if((x = b % 11) < 2){
				a[10] = 0;
			}else{
				a[10] = 11-x;
			}

			if((parseInt(cpf.charAt(9), 10) !== a[9]) || (parseInt(cpf.charAt(10), 10) !== a[10]) || cpf.match(expReg)){
				return false;
			}

			return true;
		},
		inteiro: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^[0-9]+$/;
			if(!n.test(value)){
				return false;
			}

			var n = /^[0]+/;
			if(n.test(value)){
				return false;
			}

			return true;
		},
		inteiroZero: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^[0-9]+$/;
			if(!n.test(value)){
				return false;
			}

			return true;
		},
		moeda: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			/* PALEATIVO */
			fld[0].setAttribute('step', '0.01');

			if(fld[0].validity.valid === false){
				console.warn('a');
				return false;
			}

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^-*\d+(\.\d{1,2})?$/; 
			if(!n.test(value)){
				console.warn('b');
				return false;
			}

			return true;
		},
		decimal: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			return true;
		},
		cep: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^[0-9]{5}-*[0-9]{3}$/;
			if(!n.test(value)){
				return false;
			}

			return true;
		},
		empty: function(fld, parameters){

			var lengt = fld.length;

			if(lengt < 2 && fld[0].type !== 'radio'){
				value = fld[0].value;
				if(value === ''){
					return false;
				}
			}else if(fld[0].type === 'radio'){
				for(x = 0; x < lengt; x++){
					if(fld[x].checked === true && fld[x].value === ''){
						return false;
						break;
					}
				}
			}

			return true;
		},
		tel: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^\(*[0-9]{2}\)* *-*[0-9]{4} *-* *[0-9]{4}$|^\(*[0-9]{2}\)* *-*[0-9]{5} *-* *[0-9]{4}$/;

			if(!n.test(value)){
				return false;
			}

			return true;
		},
		email: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^[a-z-A-Z0-9\._\-]+@[a-z-A-Z0-9\._\-]+\.[a-z-A-Z0-9]\.*[a-z]+$/;
			if(!n.test(value)){
				return false;
			}

			return true;
		},
		url: function(fld, parameters) {

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
			if(!n.test(value)){
				return false;
			}

			return true;
		},
		complexidadeSenha: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /([0-9a-zA-Z]+|[0-9\d]+|[a-zA-Z\d]+).{6,}/;
			if(!n.test(value)){
				return false;
			}

			return true;
		},
		igual: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var igual = Boss.getName(parameters.igual)[0];
			if(value === igual.value){
				return true;
			}
			return false;
		},
		igualNumber: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			if(Number(value) === Number(parameters.igualNumber)){
				return true;
			}
			return false;
		},
		igualId: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var igual = Boss.getById(parameters.igualId);
			if(value === igual.value){
				return true;
			}
			return false;
		},
		igualNumberId: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var igual = Boss.getById(parameters.igualNumberId);
			if(Number(value) === Number(igual.value)){
				return true;
			}
			return false;
		},
		maiorId: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			var igual = Boss.getById(parameters.maiorId);
			if(parseFloat(value) > parseFloat(igual.value)){
				return true;
			}
			return false;
		},
		maiorIgualId: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			var maiorigualid = Boss.getById(parameters.maiorIgualId);
			if(parseFloat(value) >= parseFloat(maiorigualid.value)){
				return true;
			}
			return false;
		},
		maiorIgualIdNull: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			var maiorigualidnull = Boss.getById(parameters.maiorIgualIdNull);
			if(maiorigualidnull.value == '' || parseFloat(value) >= parseFloat(maiorigualidnull.value)){
				return true;
			}
			return false;
		},
		maiorIdNull: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			var igual = Boss.getById(parameters.maiorIdNull);
			if(parseFloat(value) >= parseFloat(igual.value) || igual.value == ''){
				return true;
			}
			return false;

		},
		maior: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			if(parseFloat(value) > parseFloat(parameters.maior)){
				return true;
			}
			return false;

		},
		maiorIgual: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			if(parseFloat(value) >= parseFloat(parameters.maiorIgual)){
				return true;
			}
			return false;
		},
		menor: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			if(Number(value) < Number(parameters.menor)){
				return true;
			}
			return false;
		},
		menorIgual: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			if(parseFloat(value) <= parseFloat(parameters.menorIgual)){
				return true;
			}
			return false;

		},
		menorIgualId: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var n = /^\d+(\.[\d]+)?$/;

			value = Math.abs(value);

			/* Math.abs PARA IGNORAR VALORES NEGATIVOS */
			if(!n.test(value)){
				return false;
			}

			var igual = Boss.getById(parameters.menorIgualId);
			if(parseFloat(value) <= parseFloat(Math.abs(igual.value)) || Math.abs(igual.value) == ''){
				return true;
			}
			return false;

		},
		maiorNuloId: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}
			
			var n = /^\d+(\.[\d]+)?$/;
			if(!n.test(value)){
				return false;
			}

			var igual = Boss.getById(parameters.maiorNuloId);
			if(parseFloat(value) >= parseFloat(igual.value) || value === ''){
				return true;
			}
			return false;

		},
		diferente: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			if(value !== parameters.diferente){
				return true;
			}
			return false;
		},
		diferenteId: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			var diff = Boss.getById(parameters.diferenteId);
			if(value !== diff.value || value === ''){
				return true;
			}
			return false;
		},
		tamanhoMaximo: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			if(value.length <= parameters.tamanhoMaximo){
				return true;
			}
			return false;

		},
		tamanhoMinimo: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			if(value.length >= parameters.tamanhoMinimo){
				return true;
			}
			return false;
		},
		sicredi: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			n = /^\d+?$/;
			if(!n.test(value)){
				return false;
			}

			if(value.length === 7){
				return true;
			}
			return false;

		},
		banrisul: function(fld, parameters){

			var value = '';
			var lengt = fld.length;

			if(lengt < 2){
				value = fld[0].value;
				if(value === ''){
					return true;
				}
			}

			n = /^\d+?$/;
			if(!n.test(value)){
				return false;
			}

			if(value.length === 7){
				return true;
			}
			return false;

		},
		minSelection: function(fld, parameters){
			
			if(parameters.minSelection){

				var lengt = fld.length;

				if(fld[0].type === 'checkbox' || fld[0].type === 'radio'){
					var checkeds = 0;
					for(x = 0; x < lengt; x++){
						if(fld[x].checked === true){
							checkeds = checkeds + 1;
						}
					}
					if(checkeds < parameters.minSelection){
						return false;
					}
				}

				if(fld[0].type === 'select-multiple' || fld[0].type === 'select-one'){
					var selecteds = 0;
					var opts = fld[0].options;
					var lOpts = opts.length;
					for(o = 0; o < lOpts; o++){
						if(opts[o].selected === true){
							selecteds = selecteds + 1;
						}
					}
					if(selecteds < parameters.minSelection){
						return false;
					}
				}

				if(fld[0].type === 'file'){
					var tFls = fld[0].files.length;
					if(tFls < parameters.minSelection){
						return false;
					}
				}
			}

			return true;
		},
		maxSelection: function(fld, parameters){
			
			if(parameters.maxSelection){

				var checkeds = 0;
				var selecteds = 0;
				var lengt = fld.length;

				if(fld[0].type === 'checkbox' || fld[0].type === 'radio'){
					for(x = 0; x < lengt; x++){
						if(fld[x].checked === true){
							checkeds = checkeds + 1;
						}
					}
					if(checkeds > parameters.maxSelection){
						return false;
					}
				}

				if(fld[0].type === 'select-multiple' || fld[0].type === 'select-one'){
					var opts = fld[0].options;
					var lOpts = opts.length;
					for(o = 0; o < lOpts; o++){
						if(opts[o].selected === true){
							selecteds = selecteds + 1;
						}
					}
					if(selecteds > parameters.maxSelection){
						return false;
					}
				}

				if(fld[0].type === 'file'){
					var tFls = fld[0].files.length;
					if(tFls > parameters.maxSelection){
						return false;
					}
				}
			}

			return true;
		},
		fileMaxBytes: function(fld, parameters){
			
			if(parameters.fileMaxBytes){
				if(fld[0].type === 'file'){
					var fld = fld[0].files;
					var tFls = fld.length;
					for(f = 0; f < tFls; f++){
						if(fld[f].size > parameters.fileMaxBytes){
							return false;
						}
					}
				}
			}
			return true;
		},
		fileMimeTypes: function(fld, parameters){
			
			if(parameters.fileMimeTypes){
				if(fld[0].type === 'file'){
					var fld = fld[0].files;
					var tFls = fld.length;
					for(f = 0; f < tFls; f++){
						console.warn('Tipo de arquivo: '+fld[f].type);
						if(fld[f].type !== '' && Boss.inArray(fld[f].type,parameters.fileMimeTypes) === false){
							return false;
						}
					}
				}
			}
			return true;
		}
	},
	frmsMemory: {},
	locks: {},
	processLock: function(formid){

		if(Boss.validate.locks[formid].lock === true){

			/* UNLOCK */
			if(Boss.validate.locks[formid].initString === Boss.validate.locks[formid].changeString){
				lockChangePage = false;
				lockClosePage = false;

			/* LOCK */
			}else{
				lockChangePage = true;
				lockClosePage = true;
			}
		}

	},
	processField: function(form, nme, rules, evtstype){

		var fld = document.getElementsByName(this.frmsMemory[form].names[nme]);
		var lengt = fld.length;

		/* THE RULES */
		for(r in this.frmsMemory[form]['fields'][nme].rules){

			var rFn = Boss.validate.rules[r];

			var parameters = '';
			if(this.frmsMemory[form]['fields'][nme].rules[r].parameters){
				parameters = this.frmsMemory[form]['fields'][nme].rules[r].parameters;
			}
			var rMessage = this.frmsMemory[form]['fields'][nme].rules[r].error;
			var classError = this.frmsMemory[form]['fields'][nme].classError;
			var classOk = this.frmsMemory[form]['fields'][nme].classOk;

			if(typeof(this.frmsMemory[form]['fields'][nme].rules[r].active) == 'undefined' || this.frmsMemory[form]['fields'][nme].rules[r].active === true){
				if(typeof(rFn) === 'function'){
					if(rFn(fld, parameters) === false){

						if(evtstype !== 'keyup'){
							if(parent.Boss){
								parent.Boss.warning({message: rMessage});
							}else{
								Boss.warning({message: rMessage});
							}
						}

						// selectUnique COMPONENT
						if(fld[0].getAttribute('tabindex') == '-1' && fld[0].type === 'radio'){

							var selectUnique = fld[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;

							selectUnique.classList.add(classError);
							selectUnique.classList.remove(classOk);

						}else{

							fld[0].parentNode.classList.add(classError);
							fld[0].parentNode.classList.remove(classOk);

						}
						
						/* BREAK LOOPS */
						return false;

						/* BREAK LOOPS */
						break;
					}else{

						// selectUnique COMPONENT
						if(fld[0].getAttribute('tabindex') == '-1' && fld[0].type === 'radio'){

							var selectUnique = fld[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;

							selectUnique.classList.add(classOk);
							selectUnique.classList.remove(classError);

						}else{
							fld[0].parentNode.classList.add(classOk);
							fld[0].parentNode.classList.remove(classError);
						}
					}
				}else{
					console.warn('The rule "'+r+'" don\'t exists.');
				}
			}
		}
		return true;
	},
	goMask: function(form, nme, mask, evts){

		if(mask === 'data'){
			mask = '99/99/9999';
		}
		if(mask === 'tel'){
			mask = '(99) 9999-9999';
		}
		if(mask === 'cnpj'){
			mask = '99.999.999/9999-99';
		}
		if(mask === 'cpf'){
			mask = '999.999.999-99';
		}
		if(mask === 'cep'){
			mask = '99999-999';
		}

		if(evts.keyCode !== 37 && evts.keyCode !== 37 && evts.keyCode !== 37 && evts.keyCode !== 40){

			var fld = document.getElementsByName(this.frmsMemory[form].names[nme])[0];
			var val = fld.value;

			var spltMask = mask.split('');
			var lengtMask = spltMask.length;

			var spltVal = val.split('');

			var partial = '';

			var cut = Array();
			var outs = {};
			var pcut = 0;

			for(x = 0; x < lengtMask; x++){

				var d = /\d/;
				var a = /\w/;

				if(d.test(spltMask[x]) && a.test(spltMask[x])){
					if(!cut[pcut]){
						cut[pcut] = 0;
					}
					cut[pcut] = cut[pcut] + 1;
				}else{
					pcut++;
					cut[pcut] = spltMask[x];
					outs[spltMask[x]] = spltMask[x];
					pcut++;
				}
			}

			var v = val;
			for(o in outs){
				var reg = new RegExp('\\'+outs[o], 'g');
				v = v.replace(reg, '');
			}

			/* 10 and 11 digitos for fone */
			if(v.length === 11 && mask === '(99) 9999-9999'){
				cut = Array("(", 2, ")"," ", 5, "-", 4);
			}else if(v.length === 10 && mask === '(99) 9999-9999'){
				cut = Array("(", 2, ")"," ", 4, "-", 4);
			}

			var start = 0;

			var masked = '';

			for(c in cut){
				if(cut[c] > 0){
					masked += v.substr(start, cut[c]);
					start = start + cut[c];
				}else{
					if(v.length >= start){
						masked += cut[c];
					}
				}
			}

			if(masked === '('){
				masked = '';
			}

			fld.value = masked;

		}

	},
	process: function(form, evts){

		var frm = Boss.getById(form);
		var tFrm = frm.length;

		var statusValidate = true;

		if(evts.type === 'blur' || evts.type === 'change' || evts.type === 'keyup'){

			var fld = Boss.targt(evts);
			var nme = fld.getAttribute('name');

			/* IF NAME EXISTS */
			if(typeof(Boss.validate.frmsMemory[form]['fields'][nme]) !== 'undefined'){
				this.processField(form, nme, this.frmsMemory[form]['fields'][nme].rules, evts.type);
			}

			if(evts.type === 'blur' || evts.type === 'change' || evts.type === 'keyup' && evts.keyCode !== 8){
				if(typeof(Boss.validate.frmsMemory[form]['fields'][nme]) !== 'undefined'){
					if(typeof(Boss.validate.frmsMemory[form]['fields'][nme].mask) !== 'undefined'){
						this.goMask(form, nme, this.frmsMemory[form]['fields'][nme].mask, evts);
					}
				}
			}

			if(evts.type === 'keyup' && evts.keyCode === 8){
				if(typeof(Boss.validate.frmsMemory[form]['fields'][nme]) !== 'undefined'){
					if(typeof(Boss.validate.frmsMemory[form]['fields'][nme].mask) !== 'undefined'){
						Boss.delayPersistent2(function(){
							var fld = Boss.targt(evts);
							var nme = fld.getAttribute('name');
							Boss.validate.goMask(form, nme, Boss.validate.frmsMemory[form]['fields'][nme].mask, evts);
							Boss.trigger('keyup', fld);
						}, 1300, 'ajaxprocess');
					}
				}
			}

		/* SUBMIT */
		}else if(evts.type === 'submit' || evts === 'submit'){

			/* ACROSS FIELDS */
			for(x in this.frmsMemory[form].names){

				var nme = this.frmsMemory[form].names[x];

				/* IF NAME EXISTS */
				if(typeof(this.frmsMemory[form]['fields'][nme]) !== 'undefined'){

					var tmStatus = this.processField(form, nme, this.frmsMemory[form]['fields'][nme].rules, 'submit');
					/* BREAK TRUE */
					if(statusValidate === true && tmStatus === false){
						statusValidate = tmStatus;
					}
				}

			}
		}

		return statusValidate;

	},
	init: function(obj){

		if(Boss.getById(obj.config.formId)){

			var formid = obj.config.formId;
			var frm = Boss.getById(formid);
			var tFrm = frm.length;

			Boss.validate.locks[formid] = {};

			if(typeof(obj.config.lockPushState) !== 'undefined'){
				lockPushState = obj.config.lockPushState;
			}

			if(typeof(obj.config.lockChangePage) !== 'undefined'){
				lockChangePage = obj.config.lockChangePage;
			}

			if(lockPushState === true){

				Boss.validate.locks[formid]['lock'] = true;
				Boss.validate.locks[formid]['initString'] = JSON.stringify(Boss.serializer(formid));

			}

			if(lockPushState === false){
				Boss.validate.locks[formid]['lock'] = false;
			}

			if(frm.nodeName === 'FORM'){

				/* INIT FORM */
				this.frmsMemory[formid] = {};
				/* STORE FORM */
				this.frmsMemory[formid] = obj;
				/* INIT FORM NAMES */
				this.frmsMemory[formid]['names'] = {};

				/* FIND FIELDS NAMES OF THIS FORM */
				/* STORE FORM */
				for(f = 0; f < tFrm; f++){
					/* IF EXISTS NAME ATTRIBUTE */
					if(frm[f].getAttribute('name')){
						var nam = frm[f].getAttribute('name');
						/* STORE NAMES */
						this.frmsMemory[formid]['names'][nam] = nam;
					}
				}

				/* STOP SUBMIT EVENT */
				Boss.evts.add('submit', frm, function(evts){
					
					var valid = Boss.validate.process(formid, evts);

					if(valid === true){
						lockChangePage = false;
						lockClosePage = false;

						/* SEND BY FUNCTION */
						if(obj.send){
							obj.send();
						}
					}else{
						lockChangePage = true;
						lockClosePage = true;
					}

					/* CANCEL EVENT */
					evts.cancelBubble = true;

					if(evts.stopPropagation){
						evts.stopPropagation();
					}

					if(evts.preventDefault){
						evts.preventDefault();
					}

				});

				/* KEYUP EVENT */
				Boss.evts.add('keyup', frm, function(evts){
					Boss.delayPersistent2(function(){
						var elem = Boss.targt(evts);
						/* SOME ELEMENTS ALLOW BLUR */
						if(elem.nodeName !== 'BUTTON' && (elem.nodeName === 'INPUT' || elem.nodeName === 'TEXTAREA')){
							if(elem.type !== 'radio' && elem.type !== 'checkbox' && elem.type !== 'select' && elem.type !== 'button' && elem.type !== 'submit' && elem.type !== 'reset' && elem.type !== 'image'){
								Boss.validate.process(formid, evts);

								Boss.validate.locks[formid]['changeString'] = JSON.stringify(Boss.serializer(formid));
								Boss.validate.processLock(formid);

							}
						}
					}, 100, 'bossvalidate');
				});

				/* BLUR EVENT */
				Boss.evts.add('blur', frm, function(evts){
					var elem = Boss.targt(evts);
					/* SOME ELEMENTS ALLOW BLUR */
					if(elem.nodeName !== 'BUTTON' && (elem.nodeName === 'INPUT' || elem.nodeName === 'TEXTAREA')){
						if(elem.type !== 'radio' && elem.type !== 'checkbox' && elem.type !== 'select' && elem.type !== 'button' && elem.type !== 'submit' && elem.type !== 'reset' && elem.type !== 'image'){
							Boss.validate.process(formid, evts);
						}
					}
				});

				/* FOCUS EVENT */
				Boss.evts.add('focus', frm, function(evts){
					var elem = Boss.targt(evts);
					/* SOME ELEMENTS ALLOW BLUR */
					if(elem.nodeName !== 'BUTTON' && (elem.nodeName === 'INPUT' || elem.nodeName === 'TEXTAREA')){
						if(elem.type !== 'radio' && elem.type !== 'checkbox' && elem.type !== 'select' && elem.type !== 'button' && elem.type !== 'submit' && elem.type !== 'reset' && elem.type !== 'image'){
							Boss.validate.process(formid, evts);
						}
					}
				});

				/* CHANGE EVENT */
				Boss.evts.add('change', frm, function(evts){
					Boss.validate.process(formid, evts);

					Boss.validate.locks[formid]['changeString'] = JSON.stringify(Boss.serializer(formid));
					Boss.validate.processLock(formid);

				});

				/* PASTE EVENT */
				Boss.evts.add('paste', frm, function(evts){

					Boss.validate.locks[formid]['changeString'] = JSON.stringify(Boss.serializer(formid));
					Boss.validate.processLock(formid);

				});

				/* CUT EVENT */
				Boss.evts.add('cut', frm, function(evts){

					Boss.validate.locks[formid]['changeString'] = JSON.stringify(Boss.serializer(formid));
					Boss.validate.processLock(formid);

				});

			}else{
				console.warn('This isn\'t a form.');
				return false;
			}

		}else{
			console.warn('Bro, I did not find his form.');
			return false;
		}
	}
};

Boss.evts.add('touchmove', document, function(evt){
	if(touchEvents === false){
		touchEvents = true;
	}
});

Boss.evts.add('touchstart', document, function(evt){
	if(touchEvents === false){
		touchEvents = true;
	}
});

/* CRIA EVENTO DE SCROLL ALTERNATIVO */
Boss.evts.add('scroll', window, function(evt){
	Boss.trigger('fakescroll', window);
});

/* CRIA EVENTO DE RESIZE ALTERNATIVO */
Boss.evts.add('resize', window, function(evt){
	Boss.trigger('fakeresize', window);
});

/* SITE */
window.curY = 0;
window.lock = function(){

	curY = window.scrollY;
	document.body.classList.add('lock');
};
window.unlock = function(){

	document.body.classList.remove('lock');
	window.scrollTo(0, curY);
};

var ur = window.location.pathname;
window.url = 'pc';
ur = ur.split('/');

if(ur[1] == 'mobile'){
	url = 'mobile';
}

function checkChange(){

	if(!sessionStorage.getItem('versaoPC') && !sessionStorage.getItem('versaoMobile')){

		sessionStorage.setItem('versaoPC', 'false');
		sessionStorage.setItem('versaoMobile', 'false');
	}

	if(url === 'pc'){

		if(sessionStorage.getItem('versaoPC') === 'true'){

			Boss.getById('box-changeMobile').classList.add('hidden');
		}else{
			Boss.getById('box-changeMobile').classList.remove('hidden');
		}

	}else{

		if(sessionStorage.getItem('versaoMobile') === 'true'){

			Boss.getById('box-changeMobile').classList.add('hidden');
		}else{
			Boss.getById('box-changeMobile').classList.remove('hidden');
		}
	}
}

function cancelChange(versao){

	if(Boss.getById('box-changeMobile')){

		Boss.getById('box-changeMobile').classList.add('hidden');
	}

	if(versao === 'pc'){

		sessionStorage.setItem('versaoPC', 'true');
	}else{
		
		sessionStorage.setItem('versaoMobile', 'true');
	}
}

var animarTest = {};
var animarGallery = {};
var animarBanner = {};

/* UI.JS */
// Boss.ui = {
// 	gallery: {
// 		mem: {},
// 		openscreen: function(el){

// 			if(Boss.getById(el)){

// 				var gallery = Boss.getById(el);
// 				gallery.setAttribute('data-full-screen', 'true');
// 				gallery.classList.add('ui-gallery-full-screen');

// 				if(Boss.ui.gallery.mem[el].enterFullScreem){
// 					Boss.ui.gallery.mem[el].enterFullScreem();
// 				}

// 				document.body.classList.add('lock');

// 				if(document.documentElement.requestFullscreen){
// 					document.documentElement.requestFullscreen();
// 				}else if(document.documentElement.mozRequestFullScreen){
// 					document.documentElement.mozRequestFullScreen();
// 				}else if(document.documentElement.webkitRequestFullscreen){
// 					document.documentElement.webkitRequestFullscreen();
// 				}else if(document.documentElement.msRequestFullscreen){
// 					document.documentElement.msRequestFullscreen();
// 				}
// 			}
// 		},
// 		exitscreen: function(el){

// 			if(Boss.getById(el)){

// 				var gallery = Boss.getById(el);

// 				gallery.removeAttribute('data-full-screen');
// 				gallery.classList.remove('ui-gallery-full-screen');

// 				if(Boss.ui.gallery.mem[el].exitFullScreem){
// 					Boss.ui.gallery.mem[el].exitFullScreem();
// 				}

// 				document.body.classList.remove('lock');

// 				if(document.exitFullscreen){
// 					document.exitFullscreen();
// 				}else if(document.mozCancelFullScreen){
// 					document.mozCancelFullScreen();
// 				}else if(document.webkitExitFullscreen){
// 					document.webkitExitFullscreen();
// 				}else if(document.msExitFullscreen){
// 					document.msExitFullscreen();
// 				}

// 				window.requestAnimationFrame(function(){
// 					Boss.ui.gallery.resize(el);
// 				});
// 			}
// 		},
// 		togglefull: function(el){

// 			if(Boss.getById(el)){
// 				var gallery = Boss.getById(el);
// 				if(!gallery.getAttribute('data-full-screen')){
// 					this.openscreen(el);
// 				}else{
// 					this.exitscreen(el);
// 				}
// 			}
// 		},
// 		setPhoto: function(el){

// 			if(Boss.getById(el)){

// 				if(this.mem[el].canvas && Boss.getById(this.mem[el].canvas)){
// 					Boss.getById(this.mem[el].canvas).classList.remove('hidden');
// 				}

// 				if(this.mem[el].video && Boss.getById(this.mem[el].video)){
// 					Boss.getById(this.mem[el].video).classList.add('hidden');
// 				}

// 				if(this.mem[el].bleft && Boss.getById(this.mem[el].bleft) && this.mem[el].lengthslides > 1){
// 					Boss.getById(this.mem[el].bleft).classList.remove('hidden');
// 				}else{
// 					Boss.getById(this.mem[el].bleft).classList.add('hidden');
// 				}

// 				if(this.mem[el].bright && Boss.getById(this.mem[el].bright) && this.mem[el].lengthslides > 1){
// 					Boss.getById(this.mem[el].bright).classList.remove('hidden');
// 				}else{
// 					Boss.getById(this.mem[el].bright).classList.add('hidden');
// 				}

// 				if(this.mem[el].play && Boss.getById(this.mem[el].play)){
// 					Boss.getById(this.mem[el].play).classList.remove('ui-gallery-btn-active');
// 				}

// 				if(this.mem[el].photo && Boss.getById(this.mem[el].photo)){
// 					Boss.getById(this.mem[el].photo).classList.add('ui-gallery-btn-active');
// 				}

// 				if(typeof(this.mem[el].playerControl.pauseVideo) !== 'undefined'){
// 					Boss.ui.gallery.mem[el].playerControl.pauseVideo();
// 				}
// 				if(typeof(this.mem[el].playerControl.pause) !== 'undefined'){
// 					Boss.ui.gallery.mem[el].playerControl.pause();
// 				}

// 				if(Boss.getById(this.mem[el].subtitle)){

// 					var subtitle = Boss.getById(this.mem[el].subtitle);
// 					subtitle.classList.remove('hidden');

// 				}
// 				Boss.ui.gallery.playSlider(el);
// 			}
// 		},
// 		setVideo: function(el){


// 			if(Boss.getById(el)){

// 				if(this.mem[el].canvas && Boss.getById(this.mem[el].canvas)){
// 					Boss.getById(this.mem[el].canvas).classList.add('hidden');
// 				}
// 				if(this.mem[el].video && Boss.getById(this.mem[el].video)){
// 					Boss.getById(this.mem[el].video).classList.remove('hidden');
// 				}

// 				if(this.mem[el].bleft && Boss.getById(this.mem[el].bleft)){
// 					Boss.getById(this.mem[el].bleft).classList.add('hidden');
// 				}

// 				if(this.mem[el].bright && Boss.getById(this.mem[el].bright)){
// 					Boss.getById(this.mem[el].bright).classList.add('hidden');
// 				}

// 				if(this.mem[el].play && Boss.getById(this.mem[el].play)){
// 					Boss.getById(this.mem[el].play).classList.add('ui-gallery-btn-active');
// 				}

// 				if(this.mem[el].photo && Boss.getById(this.mem[el].photo)){
// 					Boss.getById(this.mem[el].photo).classList.remove('ui-gallery-btn-active');
// 				}

// 				if(typeof(this.mem[el].playerControl.playVideo) !== 'undefined'){
// 					Boss.ui.gallery.mem[el].playerControl.playVideo();
// 				}
// 				if(typeof(this.mem[el].playerControl.play) !== 'undefined'){
// 					Boss.ui.gallery.mem[el].playerControl.play();
// 				}

// 				if(Boss.getById(this.mem[el].subtitle)){

// 					var subtitle = Boss.getById(this.mem[el].subtitle);
// 					subtitle.classList.add('hidden');

// 				}
// 				Boss.ui.gallery.stopSlider(el);
// 			}
// 		},
// 		setBackground: function(el){

// 			if(this.mem[el].background && Boss.getById(this.mem[el].background) && this.mem[el].current > 0){

// 				var background = Boss.getById(this.mem[el].background);
// 				var subtitle = Boss.getById(this.mem[el].subtitle);

// 				for(var ls = 0; ls < this.mem[el].lengthslides; ls++){
// 					if(ls == (this.mem[el].current - 1)){
// 						var img = this.mem[el].slides[ls].querySelector('img');
// 						if(this.mem[el].setBackground === true && img !== null){
// 							background.style.backgroundImage = 'url('+img.src+')';
// 						}
// 						if(subtitle !== null && img !== null && img.getAttribute('alt') && img.getAttribute('alt') !== ''){
// 							subtitle.textContent = img.getAttribute('alt');
// 							subtitle.classList.remove('hidden');
// 						}else{
// 							subtitle.textContent = '';
// 							subtitle.classList.add('hidden');
// 						}
// 						this.mem[el].slides[ls].classList.remove('hidden');
// 					}else{
// 						this.mem[el].slides[ls].classList.add('hidden');
// 					}
// 				}

// 			}
// 		},
// 		createId: function(elem){

// 			var id;
// 			if(elem !== null){
// 				if(elem.getAttribute('id')){
// 					id = elem.getAttribute('id');
// 				}else{
// 					id = 'id-'+Math.random().toString(36).substring(2);
// 					elem.setAttribute('id', id);
// 				}
// 			}

// 			return id;
// 		},
// 		before: function(el){

// 			var current = this.mem[el].current;
// 			var lengthslides = this.mem[el].lengthslides;

// 			if((current - 1) < 1){
// 				current = lengthslides;
// 			}else{
// 				current = current - 1;
// 			}

// 			this.mem[el].current = current;
// 			this.setBackground(el);
// 		},
// 		after: function(el){

// 			var current = this.mem[el].current;
// 			var lengthslides = this.mem[el].lengthslides;

// 			if((current + 1) <= lengthslides){
// 				current = current + 1;
// 			}else{
// 				current = 1;
// 			}
// 			this.mem[el].current = current;
// 			this.setBackground(el);

// 		},
// 		playSlider: function(el){

// 			if(Boss.getById(el)){

// 				clearInterval(animarGallery[el]);
// 				animarGallery[el] = setInterval(function(){
// 					Boss.ui.gallery.after(el);
// 				}, Boss.ui.gallery.mem[el].setTime);

// 			}
// 		},
// 		stopSlider: function(el){

// 			if(Boss.getById(el) && animarGallery[el] !== false){

// 				clearInterval(animarGallery[el]);

// 			}
// 		},
// 		resize: function(el){

// 			if(Boss.getById(el)){

// 				var gallery = Boss.getById(el);
// 				var width = gallery.querySelector('.ui-gallery-width');
// 				var canvas = gallery.querySelector('.ui-gallery-canvas');
// 				var video = gallery.querySelector('.ui-gallery-video');
// 				var subtitle;

// 				if(Boss.getById(Boss.ui.gallery.mem[el].subtitle)){
// 					subtitle = Boss.getById(Boss.ui.gallery.mem[el].subtitle);
// 				}


// 				if(canvas !== null){
// 					width.style.width = '20px';
// 					width.style.maxWidth = '20px';
// 					canvas.style.height = '20px';
// 				}
// 				if(video !== null){
// 					video.style.width = '20px';
// 					video.style.height = '20px';
// 				}

// 				/* ALTURA OK */
// 				if(gallery.clientWidth > (Boss.ui.gallery.mem[el].maxHeight * 1.777777)){

// 					var h = Math.floor(Boss.ui.gallery.mem[el].maxHeight * 1.777777);
// 					gallery.classList.remove('ui-gallery-drop');

// 					if(canvas !== null){
// 						width.style.width = h+'px';
// 						width.style.maxWidth = h+'px';
// 						canvas.style.height = Boss.ui.gallery.mem[el].maxHeight+'px';
// 					}

// 					if(video !== null){
// 						video.style.width = h+'px';
// 						video.style.height = Boss.ui.gallery.mem[el].maxHeight+'px';
// 					}

// 					if(subtitle !== null){
// 						subtitle.style.top = (Boss.ui.gallery.mem[el].maxHeight - 30)+'px';
// 					}

// 				/* CALCULA PELA LARGURA*/
// 				}else{

// 					var h = Math.floor(gallery.clientWidth / 1.777777);
// 					gallery.classList.add('ui-gallery-drop');

// 					if(canvas !== null){
// 						width.style.width = gallery.clientWidth+'px';
// 						width.style.maxWidth = gallery.clientWidth+'px';
// 						canvas.style.height = h+'px';
// 					}

// 					if(video !== null){
// 						video.style.width = gallery.clientWidth+'px';
// 						video.style.height = h+'px';
// 					}

// 					if(subtitle !== null){
// 						subtitle.style.top = (h - 30)+'px';
// 					}
// 				}
// 			}
// 		},
// 		init: function(el, config){

// 			if(typeof(config) === 'undefined'){
// 				var config = {};
// 			}

// 			if(Boss.getById(el)){

// 				clearInterval(animarGallery[el]);

// 				var maxHeight = false;
// 				var setBackground = false;
// 				var setTime = 6000;
// 				var slides = [];

// 				if(config.maxHeight){
// 					maxHeight = config.maxHeight;
// 				}

// 				if(config.setBackground && config.setBackground === true){
// 					setBackground = config.setBackground;
// 				}

// 				if(config.setTime && config.setTime > 0){
// 					setTime = config.setTime * 1000;
// 				}

// 				var screensizes = Boss.screensizes();
// 				lastWidth = screensizes.viewWidth;

// 				var gallery = Boss.getById(el);

// 				var background = gallery.querySelector('.ui-gallery-background');

// 				var width = gallery.querySelector('.ui-gallery-width');
// 				var canvas = gallery.querySelector('.ui-gallery-canvas');
// 				var video = gallery.querySelector('.ui-gallery-video');

// 				var player = gallery.querySelector('.ui-gallery-player');
// 				var id_player = this.createId(player);

// 				var full = gallery.querySelector('.ui-gallery-full');

// 				var photo = gallery.querySelector('.ui-gallery-photo');
// 				var play = gallery.querySelector('.ui-gallery-play');

// 				var bleft = gallery.querySelector('.ui-gallery-left');
// 				var bright = gallery.querySelector('.ui-gallery-right');

// 				var subtitle = gallery.querySelector('.ui-gallery-subtitle');

// 				/* PREVENT DRAG */
// 				var start = false;
// 				var end = false;
// 				Boss.evts.add('dragstart', gallery, function(evts){
// 					if(evts.stopPropagation){
// 						evts.stopPropagation();
// 					}
// 					if(evts.preventDefault){
// 						evts.preventDefault();
// 					}
// 				});

// 				/* CREATE SUBTITLE */
// 				if(subtitle === null){

// 					subtitle = document.createElement('div');
// 					subtitle.classList.add('ui-gallery-subtitle');
// 					subtitle.classList.add('hidden');
// 					width.appendChild(subtitle);
// 				}

// 				/* FIND SLIDES */
// 				if(canvas !== null){

// 					slides = canvas.querySelectorAll('.ui-gallery-canvas-img');
// 					var lengthslides = 0;
// 					var current = 0;

// 					if(slides.length > 0){
// 						lengthslides = slides.length;
// 						current = 1;

// 						for(var ls = 0; ls < lengthslides; ls++){
// 							if(ls == 0){
// 								slides[ls].classList.remove('hidden');
// 							}else{
// 								slides[ls].classList.add('hidden');
// 							}
// 						}
// 					}

// 				}else{
// 					photo.classList.add('hidden');
// 				}

// 				var playerControl;

// 				this.mem[el] = {
// 					'background': this.createId(background),
// 					'slides': slides,
// 					'lengthslides': lengthslides, 
// 					'current': current, 
// 					'canvas': this.createId(canvas),
// 					'subtitle': this.createId(subtitle),
// 					'video': this.createId(video),
// 					'photo': this.createId(photo),
// 					'play': this.createId(play),
// 					'bleft': this.createId(bleft),
// 					'bright': this.createId(bright),
// 					'maxHeight': maxHeight,
// 					'playerControl': false,
// 					'interval': false,
// 					'setBackground': setBackground,
// 					'setTime': setTime
// 				};

// 				window.requestAnimationFrame(function(){
// 					Boss.ui.gallery.resize(el);
// 				});

// 				if(background !== null){
// 					Boss.ui.gallery.setBackground(el);
// 				}

// 				if(photo !== null){

// 					Boss.evts.add('click', photo, function(evts){
// 						Boss.ui.gallery.setPhoto(el);
// 					});
// 				}

// 				if(play !== null){

// 					Boss.evts.add('click', play, function(evts){
// 						Boss.ui.gallery.setVideo(el);
// 					});
// 				}

// 				if(full !== null){

// 					Boss.evts.add('click', full, function(evts){
// 						Boss.ui.gallery.togglefull(el);
// 					});
// 				}

// 				if(bleft !== null){

// 					Boss.evts.add('click', bleft, function(evts){
// 						Boss.ui.gallery.before(el);
// 						Boss.ui.gallery.stopSlider(el);
// 					});
// 				}

// 				if(bright !== null){

// 					Boss.evts.add('click', bright, function(evts){
// 						Boss.ui.gallery.after(el);
// 						Boss.ui.gallery.stopSlider(el);
// 					});
// 				}

// 				if(lengthslides > 1){
// 					Boss.evts.add('keydown', document, function(evts){

// 						/* if(evts.keyCode == 27){
// 							console.log('oi');
// 							Boss.ui.gallery.exitscreen(el);
// 						} */

// 						/* LEFT OR SPACE */
// 						if(evts.keyCode === 37 || evts.keyCode === 32){

// 							Boss.ui.gallery.before(el);
// 							Boss.ui.gallery.stopSlider(el);

// 						/* RIGHT */
// 						}else if(evts.keyCode === 39){

// 							Boss.ui.gallery.after(el);
// 							Boss.ui.gallery.stopSlider(el);
// 						}
// 					});

// 					Boss.evts.add('mousemove', canvas, function(evts){
// 						if(evts.buttons == 1 && start === false){
// 							start = evts.pageX;
// 						}
// 						if(start !== false){

// 							var current = Boss.ui.gallery.mem[el].current;
// 							var divs = Boss.ui.gallery.mem[el].slides;
// 							var drag = (start - evts.pageX) * -1;
// 							divs[(current - 1)].style.transform = 'translateX('+drag+'px)';
// 							divs[(current - 1)].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(el).clientWidth)).toFixed(2);

// 						}
// 						end = evts.pageX;
// 					});
// 					Boss.evts.add('mouseup', canvas, function(evts){
// 						if(start !== false){
// 							var drag = start - end;
// 							if(Math.abs(drag) >= 20){
// 								if(drag > 0){

// 									clearInterval(animarGallery[el]);
// 									Boss.ui.gallery.before(el);

// 								}else{
// 									clearInterval(animarGallery[el]);
// 									Boss.ui.gallery.after(el);

// 								}

// 								Boss.ui.gallery.stopSlider(el);

// 							}
// 						}
// 						var divs = Boss.ui.gallery.mem[el].slides;
// 						var t = Boss.ui.gallery.mem[el].lengthslides;
// 						for(var x = 0; x < t; x++){
// 							divs[x].removeAttribute('style');
// 						}
// 						start = false;
// 						end = false;
// 					});
// 					Boss.evts.add('mouseout', canvas, function(evts){
// 						if(start !== false){
// 							var drag = start - end;

// 							if(Math.abs(drag) >= 20){
// 								if(drag > 0){

// 									clearInterval(animarGallery[el]);
// 									Boss.ui.gallery.before(el);

// 								}else{

// 									clearInterval(animarGallery[el]);
// 									Boss.ui.gallery.after(el);

// 								}
// 								Boss.ui.gallery.stopSlider(el);
// 							}
// 						}
// 						var divs = Boss.ui.gallery.mem[el].slides;
// 						var t = Boss.ui.gallery.mem[el].lengthslides;
// 						for(var x = 0; x < t; x++){
// 							divs[x].removeAttribute('style');
// 						}
// 						start = false;
// 						end = false;
// 					});

// 					Boss.evts.add('touchmove', canvas, function(evts){
// 						if(start === false){
// 							start = evts.targetTouches[0].pageX;
// 						}
// 						if(start !== false){

// 							window.requestAnimationFrame(function(){

// 								var at = Boss.ui.gallery.mem[el].current;
// 								var divs = Boss.ui.gallery.mem[el].slides;
// 								var drag = (start - evts.targetTouches[0].pageX) * -1;
// 								divs[at].style.transform = 'translateX('+drag+'px)';
// 								divs[at].style.opacity = Math.abs(Math.cos(Math.abs(drag) * 2 / Boss.getById(el).clientWidth)).toFixed(2);

// 							});

// 						}
// 						end = evts.targetTouches[0].pageX;
// 					});
// 					Boss.evts.add('touchend', canvas, function(evts){
// 						if(start !== false){
// 							var drag = start - end;

// 							if(Math.abs(drag) >= 20){
// 								if(drag > 0){

// 									clearInterval(animarGallery[el]);
// 									Boss.ui.gallery.before(el);

// 								}else{

// 									clearInterval(animarGallery[el]);
// 									Boss.ui.gallery.after(el);

// 								}
// 								Boss.ui.gallery.stopSlider(el);
// 							}
// 						}
// 						var divs = Boss.ui.gallery.mem[el].slides;
// 						var t = Boss.ui.gallery.mem[el].lengthslides;
// 						for(var x = 0; x < t; x++){
// 							divs[x].removeAttribute('style');
// 						}
// 						start = false;
// 						end = false;
// 					});
// 				}

// 				/* RESIZE */
// 				Boss.evts.add('fakeresize', window, function(evts){

// 					var screensizes = Boss.screensizes();
// 					if(lastWidth != screensizes.viewWidth){

// 						window.requestAnimationFrame(function(){
// 							Boss.ui.gallery.resize(el);
// 						});
// 					}
// 				});

// 				gallery.style.overflow = 'visible';


// 				/* FIND VIDEO */
// 				if(video === null){

// 					play.classList.add('hidden');

// 				}else{

// 					if(config.video && config.video.id && config.video.type){

// 						if(config.video.type == 'youtube'){

// 							var tag = document.createElement('script');
// 							tag.src = "https://www.youtube.com/player_api";
// 							var firstScriptTag = document.getElementsByTagName('script')[0];
// 							firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 							Boss.evts.add('load', tag, function(evts){
// 								window.onYouTubePlayerAPIReady = function(){
// 									playerControl = new YT.Player(id_player, {
// 										height: '360',
// 										width: '640',
// 										playerVars: {'autoplay': 0, 'controls': 2, 'rel': 0, 'showinfo': 0},
// 										videoId: config.video.id,
// 									});
// 									Boss.ui.gallery.mem[el].playerControl = playerControl;
// 								}
// 							});

// 							if(typeof(YT) !== 'undefined'){
// 								playerControl = new YT.Player(id_player, {
// 									height: '360',
// 									width: '640',
// 									playerVars: {'autoplay': 0, 'controls': 2, 'rel': 0, 'showinfo': 0},
// 									videoId: config.video.id,
// 								});
// 								Boss.ui.gallery.mem[el].playerControl = playerControl;
// 							}

// 							Boss.ui.gallery.setVideo(el);
// 						}

// 						if(config.video.type == 'facebook'){

// 							Boss.getById(id_player).setAttribute('data-href', config.video.id);
// 							Boss.getById(id_player).classList.add('fb-video');


// 							if(typeof(FB) !== 'undefined'){

// 								FB.XFBML.parse();

// 								FB.Event.subscribe('xfbml.ready', function(msg){
// 									if(msg.type === 'video' && msg.id === id_player){
// 										Boss.ui.gallery.mem[el].playerControl = msg.instance;
// 									}
// 								});
// 							}

// 							Boss.evts.add('load', Boss.getById('facebook-jssdk'), function(evts){
// 								FB.Event.subscribe('xfbml.ready', function(msg){
// 									if(msg.type === 'video' && msg.id === id_player){
// 										Boss.ui.gallery.mem[el].playerControl = msg.instance;
// 									}
// 								});
// 							});

// 							Boss.ui.gallery.setVideo(el);
// 						}
// 					}else{

// 						if(play !== null){

// 							play.classList.add('hidden');
// 						}

// 						if(photo !== null){
// 							photo.classList.add('hidden');
// 						}

// 						Boss.ui.gallery.setPhoto(el);
// 					}
// 				}
// 			}
// 		}
// 	}
// }




Boss.ui = {
	
	banner: {
		bannerMemory: {},
		sizes: function(banner){

			if(Boss.getById(banner)){
				
				Boss.ux.banner.slider(banner, false);

				var sizes = Boss.screensizes();

				var bann = Boss.getById(banner);
				var canvs = Boss.ux.banner.bannerMemory[banner].canvs;
				var nav = Boss.ux.banner.bannerMemory[banner].nav;

				canvs.classList.add('ux-banner-canvas-hidden');

				bann.style.width = '';
				bann.style.height = '';
				bann.style.maxWidth = '';
				bann.style.maxHeight = '';

				canvs.style.width = '';
				canvs.style.height = '';
				canvs.style.maxWidth = '';
				canvs.style.maxHeight = '';

				Boss.delay(function(){

					var tProporcoes = Boss.ux.banner.bannerMemory.proporcoes.length;
					var proporcoes = Boss.ux.banner.bannerMemory.proporcoes;

					var imgs = 0;
					/* 1920 x 550 */
					var padraoProporcao = 3.49;
					var padraoProporcao1280 = 3.49;
					var largura = 0;
					var altura = 0;

					// ENCONTRA MENOR ALTURA
					for(x = 0; x < tProporcoes; x++){
						if(proporcoes[x].largura > 0 && proporcoes[x].altura > 0){
							imgs = imgs + 1;
							if(altura == 0){
								altura = proporcoes[x].altura;
								largura = proporcoes[x].largura;
							}
							if(proporcoes[x].altura < altura){
								altura = proporcoes[x].altura;
								largura = proporcoes[x].largura;
							}
						}
					}

					// PROCESSA PROPORÇÃO
					var lar = bann.clientWidth;
					if(altura > 0){
						padraoProporcao = (largura / altura).toFixed(4);
						padraoProporcao1280 = (1280 / altura).toFixed(4);
					}

					Boss.ux.banner.bannerMemory[banner]['padraoProporcao'] = padraoProporcao
					Boss.ux.banner.bannerMemory[banner]['padraoProporcao1280'] = padraoProporcao1280

					bann.style.width = lar+'px';
					bann.style.height = alt+'px';
					bann.style.maxWidth = lar+'px';
					bann.style.maxHeight = alt+'px';

					var alt = 0;
					var diff = 0
					if(lar < 1600){
						alt = (lar / padraoProporcao1280).toFixed(0);
						diff = lar;
						lar = (alt * padraoProporcao).toFixed(0);
						diff = lar - diff;
						diff = (diff / 2).toFixed(0);
						canvs.style.marginLeft = '-'+diff+'px';
					}else{
						alt = (lar / padraoProporcao).toFixed(0);
						canvs.style.marginLeft = '';
					}

					canvs.style.width = lar+'px';
					canvs.style.height = alt+'px';
					canvs.style.maxWidth = lar+'px';
					canvs.style.maxHeight = alt+'px';

					canvs.classList.remove('ux-banner-canvas-hidden');
					Boss.delay(function(){
						Boss.ux.banner.slider(banner, true);
					}, 6000);

				}, 300);
			}
		},
		before: function(banner){

			var bn = Boss.getById(banner);
			var lar = bn.clientWidth;

			var atual = Boss.ux.banner.bannerMemory[banner].atual;
			var total = Boss.ux.banner.bannerMemory[banner].total;
			var divs = Boss.ux.banner.bannerMemory[banner].divs;

			var legenda = Boss.ux.banner.bannerMemory[banner].legenda;

			if((atual - 1) < 0){
				atual = (total - 1);
			}else{
				atual = atual - 1;
			}

			Boss.ux.banner.bannerMemory[banner].atual = atual;

			var padraoProporcao = Boss.ux.banner.bannerMemory[banner].padraoProporcao;

			var padraoProporcao1280 = Boss.ux.banner.bannerMemory[banner].padraoProporcao1280;

			var diff = 0
			if(lar < 1600){
				alt = (lar / padraoProporcao1280).toFixed(0);
				diff = lar;
				lar = (alt * padraoProporcao).toFixed(0);
				diff = lar- diff;
				diff = (diff / 2).toFixed(0);
			}

			for(x = 0; x < total; x++){
				
				divs[x].classList.remove('hidden');

				if(atual === x){

					var y = 0;

					if(typeof(divs[(x + 1)]) !== 'undefined'){
						y = x + 1;
					}

					divs[y].classList.remove('hidden');
					divs[y].style.zIndex = '3';
					/*divs[y].style.webkitTransform = "translateX(0px)";*/
					divs[y].style.transform = "translateX(0px)";
					divs[y].classList.add('sair-esquerda');


					/*divs[x].classList.remove('hidden');*/
					divs[x].classList.add('vir-da-direita');

					/*divs[x].style.webkitTransform = "translateX("+lar+"px)";*/
					divs[x].style.transform = "translateX("+(lar - diff)+"px)";
					divs[x].style.zIndex = 4;
					divs[x].style.opacity = 1;
					divs[x].classList.remove('hidden');
					divs[x].classList.remove('sair-esquerda');
					divs[x].classList.remove('sair-direita');
					divs[x].classList.add('vir-da-direita');

					var im = divs[x].getElementsByTagName('img');
					if(im[0].getAttribute('title')){
						legenda.textContent = im[0].getAttribute('title');
					}

				}else{
					divs[x].classList.remove('vir-da-direita');
					divs[x].classList.remove('vir-da-esquerda');
					divs[x].style.zIndex = '3';
				}
			}
		},
		after: function(banner){

			var bn = Boss.getById(banner);
			var lar = bn.clientWidth;

			var atual = Boss.ux.banner.bannerMemory[banner].atual;
			var total = Boss.ux.banner.bannerMemory[banner].total;
			var divs = Boss.ux.banner.bannerMemory[banner].divs;

			var legenda = Boss.ux.banner.bannerMemory[banner].legenda;

			if((atual + 1) < total){
				atual = atual + 1;
			}else{
				atual = 0;
				if(Boss.ux.banner.bannerMemory[banner].endSlider){
					Boss.ux.banner.bannerMemory[banner].endSlider();
				}
			}

			Boss.ux.banner.bannerMemory[banner].atual = atual;

			var padraoProporcao = Boss.ux.banner.bannerMemory[banner].padraoProporcao;

			var padraoProporcao1280 = Boss.ux.banner.bannerMemory[banner].padraoProporcao1280;

			var diff = 0
			if(lar < 1600){
				alt = (lar / padraoProporcao1280).toFixed(0);
				diff = lar;
				lar = (alt * padraoProporcao).toFixed(0);
				diff = lar - diff;
				diff = (diff / 2).toFixed(0);
			}

			for(x = 0; x < total; x++){

				if(atual === x){
					var y = 0;

					if(typeof(divs[(x - 1)]) !== 'undefined'){
						y = x - 1;
					}else{
						y = total - 1;
					}

					divs[y].classList.remove('hidden');
					divs[y].style.zIndex = '3';
					/*divs[y].style.webkitTransform = "translateX(0px)";*/
					divs[y].style.transform = "translateX(0px)";
					divs[y].classList.add('sair-direita');

					/*divs[x].style.webkitTransform = "translateX(-"+lar+"px)";*/
					divs[x].style.transform = "translateX(-"+(lar - diff)+"px)";
					divs[x].style.zIndex = 4;
					divs[x].style.opacity = 1;
					divs[x].classList.remove('hidden');
					divs[x].classList.remove('sair-esquerda');
					divs[x].classList.remove('sair-direita');
					divs[x].classList.remove('vir-da-esquerda');
					divs[x].classList.add('vir-da-esquerda');

					var im = divs[x].getElementsByTagName('img');
					if(im[0].getAttribute('title')){
						legenda.textContent = im[0].getAttribute('title');
					}
				}else{
					divs[x].classList.remove('vir-da-direita');
					divs[x].classList.remove('vir-da-esquerda');
					divs[x].style.zIndex = '3';
				}
			}
		},
		slider: function(banner, action){

			var bann = Boss.getById(banner);
			var button_slide = Boss.ux.banner.bannerMemory[banner].button_slide;

			/* NOACTION */
			if(typeof(action) === 'undefined'){

				if(bann.getAttribute('data-play') === null){
					Boss.ux.banner.slider(banner, true);
				}else{
					Boss.ux.banner.slider(banner, false);
				}

			/* SLIDE SET PLAY */
			}else if(action === true){
				
				button_slide.classList.add('ux-banner-slide-pause');
				bann.setAttribute('data-play', 'true');

				clearInterval(animarBanner[banner]);
				animarBanner[banner] = setInterval(function(){
					Boss.ux.banner.after(banner);
				}, 6000);

			/* SLIDE SET PAUSE */
			}else if(action === false){

				button_slide.classList.remove('ux-banner-slide-pause');
				bann.removeAttribute('data-play');

				clearInterval(animarBanner[banner]);

			}
		},
		init: function(banner, fns){

			if(Boss.getById(banner)){

				clearInterval(animarBanner[banner]);

				var bann = Boss.getById(banner);

				var canvs = bann.querySelector('div[data-banner="canvas"]');

				var nav = bann.querySelector('div[data-banner="nav"]');

				var legenda = bann.querySelector('span[data-banner="nav-legenda"]');

				var nav_left = bann.querySelector('span[data-banner="nav-left"]');

				var nav_right = bann.querySelector('span[data-banner="nav-right"]');

				var button_slide = bann.querySelector('span[data-banner="button-slide"]');

				Boss.ux.banner.bannerMemory[banner] = {};
				Boss.ux.banner.bannerMemory[banner]['atual'] = 0;
				Boss.ux.banner.bannerMemory[banner]['canvs'] = canvs;
				Boss.ux.banner.bannerMemory[banner]['nav'] = nav;
				Boss.ux.banner.bannerMemory[banner]['legenda'] = legenda;
				Boss.ux.banner.bannerMemory[banner]['nav_left'] = nav_left;
				Boss.ux.banner.bannerMemory[banner]['nav_right'] = nav_right;
				Boss.ux.banner.bannerMemory[banner]['button_slide'] = button_slide;

				if(typeof(fns) === 'object'){
					if(typeof(fns.endSlider) === 'function'){
						Boss.ux.banner.bannerMemory[banner]['endSlider'] = fns.endSlider;
					}
				}

				bann.focus();

				var screensizes = Boss.screensizes();
				lastWidth = screensizes.viewWidth;

				/* DIVs */
				var divs = canvs.getElementsByTagName('div');
				var ldivs = divs.length;

				Boss.ux.banner.bannerMemory[banner]['total'] = ldivs;
				Boss.ux.banner.bannerMemory[banner]['divs'] = divs;

				if(ldivs < 2){
					nav.classList.add('hidden');
				}

				canvs.classList.add('ux-banner-canvas-hidden');

				/* SHOW FIRST IMAGE */
				for(i = 0; i < ldivs; i++){
					if(i > 0){
						divs[i].classList.add('hidden');
					}
					if(i < 1){
						var im = divs[i].getElementsByTagName('img');
						if(im[0] && im[0].getAttribute('title')){
							legenda.textContent =im[0].getAttribute('title');
						}
					}
				}

				var imgs = canvs.getElementsByTagName('img');
				var limgs = divs.length;

				var loadimg = 0;

				/* PRE-LOADER */
				Boss.ux.banner.bannerMemory['proporcoes'] = Array();
				for(i = 0; i < limgs; i++){
	
					// MÉDIAS DAS PROPORÇÕES
					var im = new Image();
					Boss.evts.add('load', im, function(evts){

						loadimg = loadimg + 1;

						Boss.ux.banner.bannerMemory.proporcoes.push({'largura': im.naturalWidth, 'altura': im.naturalHeight});

						if(loadimg === limgs){
							Boss.ux.banner.sizes(banner);
						}
					});
					im.src = imgs[i].getAttribute('src');
				}

				/* RESIZE EVENT */
				Boss.evts.add('resize', window, function(evt){
					Boss.delayPersistent(function(){
						Boss.ux.banner.sizes(banner);
					}, 250);
				});

				Boss.evts.add('resize', document, function(evt){
					Boss.delayPersistent(function(){
						Boss.ux.banner.sizes(banner);
					}, 250);
				});

				/* BEFORE IMAGE */
				Boss.evts.add(Boss.evtTouchUp(), nav_left, function(evts){
					Boss.ux.banner.before(banner);
					Boss.ux.banner.slider(banner, false);

					Boss.delay(function(){
						Boss.ux.banner.slider(banner, true);
					}, 20000);
				});

				/* AFTER IMAGE */
				Boss.evts.add(Boss.evtTouchUp(), nav_right, function(evts){
					Boss.ux.banner.after(banner);
					Boss.ux.banner.slider(banner, false);

					Boss.delay(function(){
						Boss.ux.banner.slider(banner, true);
					}, 20000);
				});

				/* EVENTOS DO TECLADO */
				Boss.evts.add('keyup', Boss.getById(banner), function(evts){

					var bann = Boss.getById(banner);

						/* LEFT OR SPACE */
					if(evts.keyCode === 37 || evts.keyCode === 32){

						Boss.ux.banner.before(banner);
						Boss.ux.banner.slider(banner, false);

						Boss.delay(function(){
							Boss.ux.banner.slider(banner, true);
						}, 20000);

					/* RIGHT */
					}else if(evts.keyCode === 39){

						Boss.ux.banner.after(banner);
						Boss.ux.banner.slider(banner, false);

						Boss.delay(function(){
							Boss.ux.banner.slider(banner, true);
						}, 20000);
					}
				});

				/* PLAY PAUSE */
				Boss.evts.add(Boss.evtTouchUp(), button_slide, function(evts){
					Boss.ux.banner.slider(banner);
				});

			}else{
				console.warn('Element not valid!');
			}
		}
	},
}

Boss.ui = {
	bigImage: {
		init: function(img, label){

			var mask = '<button id="boss-bigimage-close" class="boss-bigimage-close"><i class="icl ic-times" aria-hidden="true"></i></button><div class="boss-bigimage-label">'+label+'</div><img alt="imagem" src="'+img+'" />';

			if(Boss.getById('boss-bigimage')){
				var div = Boss.getById('boss-bigimage');
				div.innerHTML = mask;
			}else{
				var div = document.createElement('div');
				div.setAttribute('id', 'boss-bigimage');
				div.classList.add('boss-bigimage');
				div.innerHTML = mask;
				document.body.appendChild(div);
			}

			var screensizes = Boss.screensizes();
			var alt = screensizes.viewHeight;

			div.classList.remove('hidden');
			div.style.height = alt+'px';

			document.body.style.overflow = 'hidden';

			Boss.evts.add('click', div, function(evt){
				var el = Boss.targt(evt);
				if(el.classList.contains('boss-bigimage')){
					el.classList.add('hidden');
					document.body.style.overflow = null;
				}
			});

			Boss.evts.add('click', Boss.getById('boss-bigimage-close'), function(evt){
				var el = Boss.getById('boss-bigimage');
				el.classList.add('hidden');
				document.body.style.overflow = null;
			});
		}
	}
}