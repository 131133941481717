var animarTest = {};
var animarGallery = {};
var animarBanner = {};

Boss.ui = {
	testimonial: {
		mem: {},
		before: function(id){
			if(typeof(this.mem[id]) !== 'undefined'){
				if((this.mem[id].ativo - 1) > 0){
					this.mem[id].ativo = this.mem[id].ativo - 1;
				}else{
					this.mem[id].ativo =  this.mem[id].total - 1;
				}
			}
			this.render(id);
		},
		next: function(id){
			if(typeof(this.mem[id]) !== 'undefined'){
				if((this.mem[id].ativo + 1) < this.mem[id].total){
					this.mem[id].ativo = this.mem[id].ativo + 1;
				}else{
					this.mem[id].ativo = 0;
				}
			}
			this.render(id);
		},
		render: function(id){

			if(Boss.getById(id)){

				var ol = Boss.getById(id);
				var li = ol.getElementsByTagName('li');
				var lli = li.length;

				for(var x = 0; x < lli; x++){
					if(x == this.mem[id].ativo){
						li[x].classList.remove('hidden');
						li[x].classList.add('ux-testimonial-animate');
					}else{
						li[x].classList.add('hidden');
						li[x].classList.remove('ux-testimonial-animate');
					}
				}

				if(this.mem[id].height < ol.clientHeight){
					this.mem[id].height = ol.clientHeight;
					ol.style.minHeight = this.mem[id].height+'px';
				}
			}
		},
		init: function(id, t){

			if(typeof(t) === 'undefined'){
				var t = 6000;
			}

			if(Boss.getById(id)){

				var ol = Boss.getById(id);
				var li = ol.getElementsByTagName('li');
				var lli = li.length;

				this.mem[id] = {
					total: lli,
					ativo: 0,
					tempo: t,
					height: 0
				};

				this.render(id);

				clearInterval(animarTest[id]);

				animarTest[id] = setInterval(function(){
					Boss.ui.testimonial.next(id);
				}, this.mem[id].tempo);

				Boss.evts.add('click', ol, function(evt){

					var el = Boss.targt(evt);
					if(el.nodeName !== 'BUTTON' && el.parentElement.nodeName === 'BUTTON'){
						el = el.parentElement;
					}
					if(el.nodeName !== 'BUTTON' && el.parentElement.parentElement.nodeName === 'BUTTON'){
						el = el.parentElement.parentElement;
					}

					if(el.classList.contains('ux-testimonial-lf')){

						Boss.ui.testimonial.before(id);
						clearInterval(animarTest[id]);

					}

					if(el.classList.contains('ux-testimonial-rg')){

						Boss.ui.testimonial.next(id);
						clearInterval(animarTest[id]);
					}
				});
			}
		}
	},
	numberIncreasing: function(el, num, fn, ms, fps){

		var delay = Math.round(1000 / fps);

		var num = Number(num);
		fn = Number(fn);

		var inc = ms / delay;
		inc = fn / inc;

		if(num + inc <= fn){
			num = num + inc;

			if(num >= el.getAttribute('data-num')){
				el.setAttribute('data-num', num);
				el.textContent = Boss.numberFormat(Math.round(num));
			}

			Boss.delay(function (){
				Boss.ui.numberIncreasing(el, num, fn, ms, fps);
			}, delay);

		}else{
			el.setAttribute('data-num', fn);
			el.textContent = Boss.numberFormat(fn);
		}
	},
	countDown: function(el, time, href){

		if(el !== null){

			var diff = 0;
			var days = 0;
			var h = 0;
			var m = 0;
			var s = 0;
			var c = (Date.now() / 1000).toFixed(0);

			if(time >= c){

				diff = time - c;

				/* VERIFICA DIAS */
				if(diff > 86400){
					days = Math.floor(diff / 86400);
					/* DESCONTA DIAS INTEIROS */
					diff = diff - (days * 86400);
				}

				/* VERIFICA HORAS */
				if(diff > 3600){
					h = Math.floor(diff / 3600);
					/* DESCONTA DIAS INTEIROS */
					diff = diff - (h * 3600);
					if(h < 10){
						h = '0'+h;
					}
				}

				/* VERIFICA MINUTOS */
				if(diff > 60){
					m = Math.floor(diff / 60);
					/* DESCONTA DIAS INTEIROS */
					diff = diff - (m * 60);
					if(m < 10){
						m = '0'+m;
					}
				}

				s = diff;
				if(s < 10){
					s = '0'+s;
				}

				el.innerHTML = '<div class="ui-countdown"><span class="ui-countdown-d">'+days+'</span>dias <span class="ui-countdown-h">'+h+'</span>h <span class="ui-countdown-m">'+m+'</span>m <span class="ui-countdown-s">'+s+'</span>s</div>';

				Boss.delay(function(){
					Boss.ui.countDown(el, time, href);
				}, 200);

			}else{
				window.location.href = href;
			}
		}
	},
	
	banner: {
		bannerMemory: {},
		sizes: function(banner){

			if(Boss.getById(banner)){
				
				Boss.ui.banner.slider(banner, false);

				var sizes = Boss.screensizes();

				var bann = Boss.getById(banner);
				var canvs = Boss.ui.banner.bannerMemory[banner].canvs;

				canvs.classList.add('ux-banner-canvas-hidden');

				bann.style.width = '';
				bann.style.height = '';
				bann.style.maxWidth = '';
				bann.style.maxHeight = '';

				canvs.style.width = '';
				canvs.style.height = '';
				canvs.style.maxWidth = '';
				canvs.style.maxHeight = '';

				Boss.delay(function(){

					var tProporcoes = Boss.ui.banner.bannerMemory.proporcoes.length;
					var proporcoes = Boss.ui.banner.bannerMemory.proporcoes;

					var imgs = 0;
					/* 1920 x 550 */
					var padraoProporcao = 3.84;
					var padraoProporcao1280 = 2.56;
					var largura = 0;
					var altura = 0;

					// ENCONTRA MENOR ALTURA
					for(x = 0; x < tProporcoes; x++){
						if(proporcoes[x].largura > 0 && proporcoes[x].altura > 0){
							imgs = imgs + 1;
							if(altura == 0){
								altura = proporcoes[x].altura;
								largura = proporcoes[x].largura;
							}
							if(proporcoes[x].altura < altura){
								altura = proporcoes[x].altura;
								largura = proporcoes[x].largura;
							}
						}
					}

					// PROCESSA PROPORÇÃO
					var lar = bann.clientWidth;
					if(altura > 0){
						padraoProporcao = (largura / altura).toFixed(4);
						padraoProporcao = (largura / altura).toFixed(4);
					}

					Boss.ui.banner.bannerMemory[banner]['padraoProporcao'] = padraoProporcao;
					Boss.ui.banner.bannerMemory[banner]['padraoProporcao1280'] = padraoProporcao1280;

					bann.style.width = lar+'px';
					bann.style.height = alt+'px';
					bann.style.maxWidth = lar+'px';
					bann.style.maxHeight = alt+'px';

					var alt = 0;
					var diff = 0
					if(lar < 1600){
						alt = (lar / padraoProporcao1280).toFixed(0);
						diff = lar;
						lar = (alt * padraoProporcao).toFixed(0);
						diff = lar - diff;
						diff = (diff / 2).toFixed(0);
						canvs.style.marginLeft = '-'+diff+'px';
					}else{
						alt = (lar / padraoProporcao).toFixed(0);
						canvs.style.marginLeft = '';
					}

					canvs.style.width = lar+'px';
					canvs.style.height = alt+'px';
					canvs.style.maxWidth = lar+'px';
					canvs.style.maxHeight = alt+'px';

					canvs.classList.remove('ux-banner-canvas-hidden');
					Boss.delay(function(){
						Boss.ui.banner.slider(banner, true);
					}, 6000);

				}, 300);
			}
		},
		before: function(banner){

			if(Boss.getById(banner)){

				var bn = Boss.getById(banner);
				var lar = bn.clientWidth;

				var atual = Boss.ui.banner.bannerMemory[banner].atual;
				var total = Boss.ui.banner.bannerMemory[banner].total;
				var divs = Boss.ui.banner.bannerMemory[banner].divs;

				var legenda = Boss.ui.banner.bannerMemory[banner].legenda;

				if((atual - 1) < 0){
					atual = (total - 1);
				}else{
					atual = atual - 1;
				}

				Boss.ui.banner.bannerMemory[banner].atual = atual;

				var padraoProporcao = Boss.ui.banner.bannerMemory[banner].padraoProporcao;

				var padraoProporcao1280 = Boss.ui.banner.bannerMemory[banner].padraoProporcao1280;

				var diff = 0;
				if(lar < 1600){
					alt = (lar / padraoProporcao1280).toFixed(0);
					diff = lar;
					lar = (alt * padraoProporcao).toFixed(0);
					diff = lar- diff;
					diff = (diff / 2).toFixed(0);
				}

				for(x = 0; x < total; x++){
					
					divs[x].classList.remove('hidden');

					if(atual === x){

						var y = 0;

						if(typeof(divs[(x + 1)]) !== 'undefined'){
							y = x + 1;
						}

						divs[y].classList.remove('hidden');
						divs[y].style.zIndex = '3';
						/*divs[y].style.webkitTransform = "translateX(0px)";*/
						divs[y].style.transform = "translateX(0px)";
						divs[y].classList.add('sair-esquerda');


						/*divs[x].classList.remove('hidden');*/
						divs[x].classList.add('vir-da-direita');

						/*divs[x].style.webkitTransform = "translateX("+lar+"px)";*/
						divs[x].style.transform = "translateX("+(lar - diff)+"px)";
						divs[x].style.zIndex = 4;
						divs[x].style.opacity = 1;
						divs[x].classList.remove('hidden');
						divs[x].classList.remove('sair-esquerda');
						divs[x].classList.remove('sair-direita');
						divs[x].classList.add('vir-da-direita');

						var im = divs[x].getElementsByTagName('img');
						if(im[0].getAttribute('alt')){
							legenda.textContent = im[0].getAttribute('alt');
						}

					}else{
						divs[x].classList.remove('vir-da-direita');
						divs[x].classList.remove('vir-da-esquerda');
						divs[x].style.zIndex = '3';
					}
				}
			}
		},
		after: function(banner){

			if(Boss.getById(banner)){

				var bn = Boss.getById(banner);
				var lar = bn.clientWidth;

				var atual = Boss.ui.banner.bannerMemory[banner].atual;
				var total = Boss.ui.banner.bannerMemory[banner].total;
				var divs = Boss.ui.banner.bannerMemory[banner].divs;

				var legenda = Boss.ui.banner.bannerMemory[banner].legenda;

				if((atual + 1) < total){
					atual = atual + 1;
				}else{
					atual = 0;
					if(Boss.ui.banner.bannerMemory[banner].endSlider){
						Boss.ui.banner.bannerMemory[banner].endSlider();
					}
				}

				Boss.ui.banner.bannerMemory[banner].atual = atual;

				var padraoProporcao = Boss.ui.banner.bannerMemory[banner].padraoProporcao;

				var padraoProporcao1280 = Boss.ui.banner.bannerMemory[banner].padraoProporcao1280;

				var diff = 0
				if(lar < 1600){
					alt = (lar / padraoProporcao1280).toFixed(0);
					diff = lar;
					lar = (alt * padraoProporcao).toFixed(0);
					diff = lar - diff;
					diff = (diff / 2).toFixed(0);
				}

				for(x = 0; x < total; x++){

					if(atual === x){
						var y = 0;

						if(typeof(divs[(x - 1)]) !== 'undefined'){
							y = x - 1;
						}else{
							y = total - 1;
						}

						divs[y].classList.remove('hidden');
						divs[y].style.zIndex = '3';
						/*divs[y].style.webkitTransform = "translateX(0px)";*/
						divs[y].style.transform = "translateX(0px)";
						divs[y].classList.add('sair-direita');

						/*divs[x].style.webkitTransform = "translateX(-"+lar+"px)";*/
						divs[x].style.transform = "translateX(-"+(lar - diff)+"px)";
						divs[x].style.zIndex = 4;
						divs[x].style.opacity = 1;
						divs[x].classList.remove('hidden');
						divs[x].classList.remove('sair-esquerda');
						divs[x].classList.remove('sair-direita');
						divs[x].classList.remove('vir-da-esquerda');
						divs[x].classList.add('vir-da-esquerda');

						var im = divs[x].getElementsByTagName('img');
						if(im[0].getAttribute('alt')){
							legenda.textContent = im[0].getAttribute('alt');
						}
					}else{
						divs[x].classList.remove('vir-da-direita');
						divs[x].classList.remove('vir-da-esquerda');
						divs[x].style.zIndex = '3';
					}
				}
			}
		},
		slider: function(banner, action){

			if(Boss.getById(banner)){

				var bann = Boss.getById(banner);

				/* NOACTION */
				if(typeof(action) === 'undefined'){

					if(bann.getAttribute('data-play') === null){
						Boss.ui.banner.slider(banner, true);
					}else{
						Boss.ui.banner.slider(banner, false);
					}

				/* SLIDE SET PLAY */
				}else if(action === true){
					
					if(typeof(Boss.ui.banner.bannerMemory[banner].button_slide) !== 'undefined'){
						button_slide.classList.add('ux-banner-slide-pause');
					}

					bann.setAttribute('data-play', 'true');

					clearInterval(animarBanner[banner]);
					animarBanner[banner] = setInterval(function(){
						Boss.ui.banner.after(banner);
					}, 6000);

				/* SLIDE SET PAUSE */
				}else if(action === false){

					if(typeof(Boss.ui.banner.bannerMemory[banner].button_slide) !== 'undefined'){
						button_slide.classList.remove('ux-banner-slide-pause');
					}

					bann.removeAttribute('data-play');

					clearInterval(animarBanner[banner]);

				}
			}
		},
		init: function(banner, fns){

			if(Boss.getById(banner)){

				clearInterval(animarBanner[banner]);

				var bann = Boss.getById(banner);

				var canvs = bann.querySelector('div[data-banner="canvas"]');

				var legenda = bann.querySelector('span[data-banner="nav-legenda"]');

				var nav_left = bann.querySelector('button[data-banner="nav-left"]');

				var nav_right = bann.querySelector('button[data-banner="nav-right"]');

				var button_slide = bann.querySelector('span[data-banner="button-slide"]');

				Boss.ui.banner.bannerMemory[banner] = {};
				Boss.ui.banner.bannerMemory[banner]['atual'] = 0;
				Boss.ui.banner.bannerMemory[banner]['canvs'] = canvs;
				Boss.ui.banner.bannerMemory[banner]['legenda'] = legenda;
				Boss.ui.banner.bannerMemory[banner]['nav_left'] = nav_left;
				Boss.ui.banner.bannerMemory[banner]['nav_right'] = nav_right;

				if(button_slide){

					Boss.ui.banner.bannerMemory[banner]['button_slide'] = button_slide;

					/* PLAY PAUSE */
					Boss.evts.add('click', button_slide, function(evts){
						Boss.ui.banner.slider(banner);
					});

				}

				if(typeof(fns) === 'object'){
					if(typeof(fns.endSlider) === 'function'){
						Boss.ui.banner.bannerMemory[banner]['endSlider'] = fns.endSlider;
					}
				}

				/* DIVs */
				var divs = canvs.getElementsByTagName('div');
				var ldivs = divs.length;

				Boss.ui.banner.bannerMemory[banner]['total'] = ldivs;
				Boss.ui.banner.bannerMemory[banner]['divs'] = divs;

				if(ldivs < 2){
					nav_left.classList.add('hidden');
					nav_right.classList.add('hidden');
					legenda.classList.add('hidden');
				}

				canvs.classList.add('ux-banner-canvas-hidden');

				/* SHOW FIRST IMAGE */
				for(i = 0; i < ldivs; i++){
					if(i > 0){
						divs[i].classList.add('hidden');
					}
					if(i < 1){
						var im = divs[i].getElementsByTagName('img');
						if(im[0] && im[0].getAttribute('alt')){
							legenda.textContent = im[0].getAttribute('alt');
						}
					}
				}

				var imgs = canvs.getElementsByTagName('img');
				var limgs = divs.length;

				var loadimg = 0;

				/* PRE-LOADER */
				Boss.ui.banner.bannerMemory['proporcoes'] = Array();
				for(i = 0; i < limgs; i++){
	
					// MÉDIAS DAS PROPORÇÕES
					var im = new Image();
					Boss.evts.add('load', im, function(evts){

						loadimg = loadimg + 1;

						Boss.ui.banner.bannerMemory.proporcoes.push({'largura': im.naturalWidth, 'altura': im.naturalHeight});

						if(loadimg === limgs){
							Boss.ui.banner.sizes(banner);
						}
					});
					im.src = imgs[i].getAttribute('src');
				}


				var screensizes = Boss.screensizes();
				lastWidth = screensizes.viewWidth;

				/* RESIZE EVENT */
				Boss.evts.add('resize', window, function(evt){

					var screensizes = Boss.screensizes();
					if(lastWidth != screensizes.viewWidth){
						Boss.delayPersistent(function(){
							lastWidth = screensizes.viewWidth;
							Boss.ui.banner.sizes(banner);
						}, 250);
					}
				});

				/* BEFORE IMAGE */
				Boss.evts.add('click', nav_left, function(evts){
					Boss.ui.banner.before(banner);
					Boss.ui.banner.slider(banner, false);

					Boss.delay(function(){
						Boss.ui.banner.slider(banner, true);
					}, 20000);
				});

				/* AFTER IMAGE */
				Boss.evts.add('click', nav_right, function(evts){
					Boss.ui.banner.after(banner);
					Boss.ui.banner.slider(banner, false);

					Boss.delay(function(){
						Boss.ui.banner.slider(banner, true);
					}, 20000);
				});

				/* EVENTOS DO TECLADO */
				Boss.evts.add('keyup', Boss.getById(banner), function(evts){

					var bann = Boss.getById(banner);

						/* LEFT OR SPACE */
					if(evts.keyCode === 37 || evts.keyCode === 32){

						Boss.ui.banner.before(banner);
						Boss.ui.banner.slider(banner, false);

						Boss.delay(function(){
							Boss.ui.banner.slider(banner, true);
						}, 20000);

					/* RIGHT */
					}else if(evts.keyCode === 39){

						Boss.ui.banner.after(banner);
						Boss.ui.banner.slider(banner, false);

						Boss.delay(function(){
							Boss.ui.banner.slider(banner, true);
						}, 20000);
					}
				});
			}else{
				console.warn('Element not valid!');
			}
		}
	},
	bigImage: {
		init: function(img, label){

			var mask = '<button id="boss-bigimage-close" class="boss-bigimage-close"><i class="icl ic-times" aria-hidden="true"></i></button><div class="boss-bigimage-label">'+label+'</div><img alt="imagem" src="'+img+'" />';

			if(Boss.getById('boss-bigimage')){
				var div = Boss.getById('boss-bigimage');
				div.innerHTML = mask;
			}else{
				var div = document.createElement('div');
				div.setAttribute('id', 'boss-bigimage');
				div.classList.add('boss-bigimage');
				div.innerHTML = mask;
				document.body.appendChild(div);
			}

			var screensizes = Boss.screensizes();
			var alt = screensizes.viewHeight;

			div.classList.remove('hidden');
			div.style.height = alt+'px';

			/*document.body.classList.add('lock');*/

			Boss.evts.add('click', div, function(evt){
				var el = Boss.targt(evt);
				if(el.classList.contains('boss-bigimage')){
					el.classList.add('hidden');
				}
			});

			Boss.evts.add('click', Boss.getById('boss-bigimage-close'), function(evt){
				var el = Boss.getById('boss-bigimage');
				el.classList.add('hidden');
				/*document.body.classList.remove('lock');*/
			});
		}
	}
};